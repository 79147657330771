import React from 'react'
import './Best.css'
import forte from '../../../Resources/Images/forte.webp'
import pure from '../../../Resources/Images/artron.png'


const Best = () => {
    return (
        <div className='best-main'>
            <div className='best-wrapper'>
                <h2 className='best-main-header'>NAJBOLJE PRODAJANO</h2>
                <div className='best-row'>
                    <div className='best-cell hidden-cell'>
                        <img className='love-img moving-img' alt='love' src={forte} />
                    </div>
                    <div className='best-cell'>
                        <div className='about-content-wrapper super-wrapper'>
                            <h2 className='about-header'>
                                Kolagen Forte
                            </h2>
                            <p className='about-paragraph'>
                            Negujte svojo lepoto od znotraj! Ko se staramo, naša koža potrebuje več podpore za boj proti
prostim radikalom in zaščito pred škodljivimi zunanjimi dejavniki. FORTE je posebno močna različica
naše originalne formule PURE, okrepljena z antioksidanti, ki ustreza potrebam kože žensk, starejših
od 40 let.                            </p>
                            
                            <div className='underlined-button'>
                                <a href="/item/26">POSLOVITE SE OD GLOBOKIH GUBIC</a>
                            </div>
                        </div>
                    </div>
                    <div className='best-cell mobile-hidden'>
                        <img className='love-img moving-img2' alt='love' src={forte} />
                    </div>
                </div>
                <div className='best-row'>
                    <div className='best-cell'>
                        <img className='love-img moving-img' alt='love' src={pure} />
                    </div>
                    <div className='best-cell'>
                        <div className='about-content-wrapper super-wrapper'>
                            <h2 className='about-header'>
                                Artron Extreme
                            </h2>
                            <p className='about-paragraph'>
                            Naš specialist za nego sklepov je pravkar postal EKSTREMEN. ARTRON COLLAGEN®.
EXTREME je bil posebej razvit za moške in ženske, ki skrbijo za splošno nego svojih
sklepov, in sicer za pomoč, podporo ter ohranjanje zdravja vezivnega tkiva, hrustanca, kosti
in mišic. Za tiste, ki trpijo zaradi bolečin in okorelosti sklepov ali želijo poskrbeti za svoje
sklepe.                            </p>
                            <div className='underlined-button'>
                                <a href="/item/74">ŽIVITE ŽIVLJENJE NA POLNO</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Best
