import { SET_ITEMS_DETAILS } from '../actions/itemsDetailsActions';

const initialState = {
    itemsDetails: []
};

const itemsDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ITEMS_DETAILS:
            return {
                ...state,
                itemsDetails: action.payload
            };
        default:
            return state;
    }
};

export default itemsDetailsReducer; 