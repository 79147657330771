import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import currentCategoryReducer from './reducers/currentCategoryReducer';
import itemReducer from './reducers/itemReducer';
import cartReducer from './reducers/cartReducer';
import productReducer from './reducers/productReducer';
import categoryReducer from './reducers/categoryReducer';
import postReducer from './reducers/postReducer';
import itemsDetailsReducer from './reducers/itemsDetailsReducer';
import mediaReducer from './reducers/mediaReducer';
import customerReducer from './reducers/customerReducer';
import shippingReducer from './reducers/shippingReducer';
import selectedPostReducer from './reducers/selectedPostReducer';
import loadingReducer from './reducers/loadingReducer';
import couponReducer from './reducers/couponReducer';

const persistConfig = {
  key: 'root',
  storage,
  // Add any other config options here...
};

// Combine reducers
const rootReducer = combineReducers({
  category: currentCategoryReducer,
  item: itemReducer,
  cart: cartReducer,
  products: productReducer,
  categories: categoryReducer,
  posts: postReducer,
  itemsDetails: itemsDetailsReducer,
  media:mediaReducer,
  customer: customerReducer,
  shipping: shippingReducer,
  selectedPost:selectedPostReducer,
  loading: loadingReducer,
  coupon: couponReducer,
  // ... add other reducers here
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // ... add any middleware or other store enhancers
});

export const persistor = persistStore(store);