import React, { useEffect, useState } from 'react';
import './Items.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedItem } from '../../../Store/actions/itemActions';
import './BlogCarousel.css'
import { setSelectedPost } from '../../../Store/actions/selectedPostAction';
const BlogCarousel = () => {
    const posts = useSelector(state => state.posts.posts); // Access posts from Redux store
    const media = useSelector(state => state.media.media); // Access media from Redux store
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loadedImages, setLoadedImages] = useState({});

    useEffect(() => {
        const loadedImages = {};

        posts.forEach(post => {
            const matchingMedia = media.find(item => item.id === post.acf.blog_post_image);

            if (matchingMedia) {
                loadedImages[post.id] = matchingMedia.source_url;
            }
        });

        setLoadedImages(loadedImages);
    }, [posts, media]);

    const handleSelectItem = (post) => {
        window.scrollTo(0, 0);

        dispatch(setSelectedItem(post));
        navigate(`/item/${post.id}`);
    };

    const handlePostClick = (post) => {
        window.scrollTo(0, 0);

        dispatch(setSelectedPost(post));

    
        // Pass the post ID (or any other necessary data) to the BlogPost component
        navigate(`/blog/blog-post/${post.id}`);
    };

    return (
        <div className='items-main super-carousel-main'>
            <div className='items-wrapper suprawrapper'>
                <div className='desktop-items'>
                    <Swiper
                        spaceBetween={0} // Adjust the space between slides
                        slidesPerView={5} // Show 5 items per page
                    >
                        {posts.map((post, index) => (
                            <SwiperSlide key={index}>
                                <div className='item-cell-mobile'>
                                    {loadedImages[post.id] && (
                                        <div className='carousel-image-container'>
                                            <img src={loadedImages[post.id]} alt={post.title.rendered} className='cell-img smurfimg' onClick={() => post.id && handlePostClick(post)} />
                                            <div className='carousel-title' onClick={() => post.id && handlePostClick(post)}><p className='glupdi'>{post.title.rendered}</p></div>
                                        </div>

                                    )}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className='mobile-itemss itemssss'>
                    <Swiper
                        spaceBetween={0} // Adjust the space between slides
                        slidesPerView={2} // Show 2 items per page
                    >
                        {posts.map((post, index) => (
                            <SwiperSlide key={index}>
                                <div className='item-cell-mobile'>
                                    {loadedImages[post.id] && (
                                        <div className='carousel-image-container'>
                                        <img src={loadedImages[post.id]} alt={post.title.rendered} className='cell-img smurfimg mobiii' onClick={() => post.id && handlePostClick(post)} />
                                        <div className='carousel-title' onClick={() => post.id && handlePostClick(post)}><p className='glupdi mobii'>{post.title.rendered}</p></div>
                                    </div>
                                    )}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default BlogCarousel;