import React, { useEffect } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import './About.css'
import About from '../Main/Components/About'
import Media from '../Main/Components/Media'
import Items from '../Main/Components/Items'
import BlogCarousel from '../Main/Components/BlogCarousel'
import Footer from '../../Components/Footer/Footer'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import CookieConsent from '../../Cookies/CookieConsent'

const Aboutt = () => {
    const isLoading = useSelector(state => state.loading);
    const [cookies] = useCookies(["cookieConsent"]);

    

    if (!isLoading.products ) {
      return <div className='loading-div-absolute'>
        <div className="lds-ripple"><div></div><div></div></div>
        <p className='loading-paragraph'>Pripravljamo vse potrebno za prvi zagon</p>
      </div>; // Your loading screen/component here
    }
    return (
        <div>
             <Helmet>
        <title>O nas</title>
        <meta name="description" content="Spoznajte našo pot in zavezanost dobremu počutju. Pridružite se naši kolagenski revoluciji!" />
        <link rel="canonical" href="https://velins.shop/about" />
            </Helmet>
            <Navbar />
            {!cookies.cookieConsent && <CookieConsent />}

            <div className='hero-main'>
                <div className='hero-wrapper'>
                    <h1 className='main-header'>O NAS</h1>
                    <p className='main-paragraph HHH'>Smo slovensko podjetje, ki združuje <span className='boldovana'>strast do zdravega življenjskega sloga in željo po izjemnosti.</span> Z našim delom si prizadevamo ustvariti svet, kjer se ljudje počutijo <span className='boldovana'>samozavestno, vitalno in polni energije.</span></p>
                    <p className='main-paragraph HHH'>Skupaj z našimi strankami gradimo pot do boljšega počutja. S ponosom ponujamo izdelke, ki
so rezultat najnovejših znanstvenih dognanj in visokih standardov proizvodnje.</p>
                    <p className='main-paragraph HHH'>Prehranska dopolnila Gold collagen so narejena na Japonskem.</p>
                    <p className='main-paragraph HHH'>Naša strast do inovacij, skrb za stranke in trajnostne prakse nas vodijo pri ustvarjanju <span className='boldovana'>pozitivnih sprememb </span>v življenju posameznikov.</p>
                    <p className='main-paragraph HHHL'>Želimo postati zaupanja vreden vir informacij in kakovostnih izdelkov za vse, ki skrbijo ZASE!</p>

                </div>
            </div>
            <About/>
            <Media/>
            <Items/>
            <BlogCarousel/>
            <Footer/>
        </div>
    )
}

export default Aboutt
