import { useEffect, useState, useRef } from 'react';
import './Shop.css'
import Navbar from '../../Components/Navbar/Navbar';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setProducts } from '../../Store/actions/productActions';
import { setCategories } from '../../Store/actions/categoryActions';
import { addToCart } from '../../Store/actions/cartActions'; // Update the path as needed
import { useNavigate, useLocation } from 'react-router-dom';
import { setSelectedItem } from '../../Store/actions/itemActions';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Helmet } from 'react-helmet-async';
import { useCookies } from 'react-cookie'
import CookieConsent from '../../Cookies/CookieConsent';

const Shop = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const categories = useSelector(state => state.categories.categories); // Get categories from Redux

    const [selectedCategory, setSelectedCategory] = useState(null);
    const products = useSelector(state => state.products.products); // Retrieve products from Redux store
    const products2 = useSelector(state => state.products.products); // Retrieve products from Redux store

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [size, setSize] = useState(0);
    const ref = useRef(null);
    const [scrollUpdateDelay, setScrollUpdateDelay] = useState(false);
    const swiperRef = useRef(null);
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const location = useLocation();
    const [category, setCategory] = useState(location.state?.category);
    const [cookies] = useCookies(["cookieConsent"]);

    
    


    useEffect(() => {
        if (category == 17 || category == 18 || category == 19 || category == 20) {
            handleCategoryClick(category);
        }

    }, [category, categories])

    const handleAddToCart = (product) => {
        if (product.stock_quantity > 0) {
            dispatch(addToCart({
                ...product,
                quantity: 1 // Assuming you start with a quantity of 1, modify as needed
            }));

            setAlertMessage("Produkt dodan");
            setShowAlert(true);

        } else {
            // Optionally, you can handle the case where the product is out of stock
            // For example, display a message to the user
            setAlertMessage("Ni na zalogi");
            setShowAlert(true);

        }

        setTimeout(() => {
            hideAlert();
        }, 1000);
    };

    const hideAlert = () => {
        setAlertMessage("");
        setShowAlert(false);
    };

    useEffect(() => {
        axios.get(`${baseUrl}/products`)
            .then(response => {
                dispatch(setProducts(response.data));
            })
            .catch(error => {
                console.error('Error fetching products:', error);
            });

        axios.get(`${baseUrl}/categories`)
            .then(response => {
                const sortedCategories = [...response.data].sort((a, b) => a.slug.localeCompare(b.slug));
                dispatch(setCategories(sortedCategories));
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });


            updateSize(); // Set initial size

    }, [dispatch, baseUrl]);

    useEffect(() => {



        if (categories.length > 0 && !selectedCategory) {
            // Set the first category as the selected one by default
            setSelectedCategory(categories[0].id);

        }

    }, [categories, selectedCategory]);

    // Create a ref object to keep track of category refs
    const categoryRefs = useRef({});

    // Assign a ref to each category section
    const assignCategoryRef = (element, categoryId) => {
        if (element && !categoryRefs.current[categoryId]) {
            categoryRefs.current[categoryId] = element;
        }
    };

    // Scroll to a category section on navigation click
    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId);
        setScrollUpdateDelay(true); // Prevent scroll handler from updating category immediately

        const categoryElement = categoryRefs.current[categoryId];
        if (categoryElement) {
            categoryElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

            // Reset the delay after the duration of your smooth scroll (e.g., 600ms)
            setTimeout(() => setScrollUpdateDelay(false), 600);
        }
    };

    // Update selected category based on scroll position
    useEffect(() => {
        const handleScroll = () => {
            if (scrollUpdateDelay) return; // Skip if delay is active

            const categoryIds = Object.keys(categoryRefs.current);
            for (const categoryId of categoryIds) {
                const element = categoryRefs.current[categoryId];
                const bounding = element.getBoundingClientRect();
                if (bounding.top >= 0 && bounding.top <= window.innerHeight / 2) {
                    setSelectedCategory(parseInt(categoryId, 10));
                    break;
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [scrollUpdateDelay]);



    const getProductsByCategory = (categoryId) => {
        const filteredProducts = products.filter(product =>
            product.categories.some(category => category.id === categoryId)
        );



        // Sorting by SKU
        filteredProducts.sort((a, b) => {
            if (a.menu_order < b.menu_order) {
                return -1; // a comes first
            }
            if (a.menu_order > b.menu_order) {
                return 1; // b comes first
            }
            return 0; // no sorting needed if they are equal
        });



        return filteredProducts;
    };

    const getAdjustedProducts = (categoryId) => {
        const productsByCategory = getProductsByCategory(categoryId);
        const itemCount = productsByCategory.length;
        let itemsPerRow = 3; // Default items per row

        // Adjust items per row based on screen width
        if (windowWidth < 800) {
            itemsPerRow = 1;
        } else if (windowWidth < 1400) {
            itemsPerRow = 2;
        }

        const emptyCellsNeeded = itemsPerRow - (itemCount % itemsPerRow);
        if (emptyCellsNeeded === itemsPerRow) return productsByCategory; // No empty cells needed

        return [...productsByCategory, ...Array(emptyCellsNeeded).fill({})];
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const updateSize = () => {
        if (ref.current) {
            setSize(ref.current.offsetWidth); // Set height equal to the width
        }
    };

    useEffect(() => {
        updateSize(); // Set initial size
        window.addEventListener('resize', updateSize); // Update size on resize

        return () => window.removeEventListener('resize', updateSize); // Cleanup
    }, []);



    const handleSelectItem = (product) => {
        sessionStorage.setItem('shopScrollPosition', window.scrollY.toString());

        window.scrollTo(0, 0);

        dispatch(setSelectedItem(product));
        navigate(`/item/${product.id}`);
    };

    useEffect(() => {
        // Check if there's a saved scroll position
        const savedScrollPosition = sessionStorage.getItem('shopScrollPosition');
        
        if (savedScrollPosition) {
            window.scrollTo(0, parseInt(savedScrollPosition, 10));
            // Optionally, clear the saved position if you don't need it anymore
            console.log("SCROLLING")
            

        }

        //sessionStorage.removeItem('shopScrollPosition');
    }, [products2]);

    useEffect(() => {




        if (swiperRef.current && selectedCategory != null) {
            const selectedIndex = categories.findIndex(category => category.id === selectedCategory);
            if (selectedIndex !== -1) {
                swiperRef.current.slideTo(selectedIndex);
            }
        }

    }, [selectedCategory, categories]);

    
    useEffect(() => {
        const updateSize = () => {
            if (ref.current) {
                setSize(ref.current.offsetWidth);
            }
        };
    
        // Call updateSize on resize
        window.addEventListener('resize', updateSize);
    
        // Initial call
        updateSize();
    
        // Cleanup
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    
    // Update size when products are loaded
    useEffect(() => {
        if (products.length > 0) {
            updateSize();
        }
    }, [products]);

    const isLoading = useSelector(state => state.loading);

  if (!isLoading.products && !isLoading.categories) {
    return <div className='loading-div-absolute'>
      <div className="lds-ripple"><div></div><div></div></div>
      <p className='loading-paragraph'>Pripravljamo vse potrebno za prvi zagon</p>
    </div>; // Your loading screen/component here
  }


    return (
        <div>
            <Helmet>
                <title>Trgovina</title>
                <meta name="description" content="Raziskujte Velins Shop za naravno povečanje kolagena - naravno izboljšajte lepoto in zdravje!" />
                <link rel="canonical" href="https://velins.shop/shop" />
            </Helmet>

            <Navbar />
            {!cookies.cookieConsent && <CookieConsent />}

            <div className='shop-main-desktop'>
                <div className='navigations-carousel'>
                    <Swiper
                        onSwiper={(swiper) => { swiperRef.current = swiper; }}
                        spaceBetween={0}
                        centeredSlides={true}
                        slidesPerView='auto'

                    >
                        {categories.map(category => (
                            category.name !== "Uncategorized" && (
                                <SwiperSlide
                                    key={category.id}
                                    onClick={() => handleCategoryClick(category.id)}
                                >

                                    <div className={`carousel-category-item ${selectedCategory === category.id ? 'selected-navigation' : ''}`}>
                                        {category.name}
                                    </div>
                                </SwiperSlide>)
                        ))}
                    </Swiper>
                </div>
                <div className='shop-wrapper'>
                    <div className='left-navigation'>
                        <div className='shop-nav-links-wrapper'>
                            {categories.map(category => (
                                category.name !== "Uncategorized" && (
                                    <p
                                        key={category.id}
                                        className={`shop-nav-item ${selectedCategory === category.id ? 'selected-navigation' : ''}`}
                                        onClick={() => handleCategoryClick(category.id)}
                                    >
                                        {category.name}
                                    </p>
                                )
                            ))}
                        </div>
                    </div>
                    <div className='shop-content'>
                        {categories.map(category => (
                            category.name !== "Uncategorized" && (
                                <div
                                    key={category.id}
                                    className='category-holder'
                                    ref={el => assignCategoryRef(el, category.id)}
                                >
                                    <div className='category-name-holder'>
                                        <h2 className='category-name-header'>{category.name}</h2>
                                    </div>

                                    <div className='category-items-holder'>
                                        {getAdjustedProducts(category.id).map((product, index) => (
                                            <div key={index} className={`product-item-shop-cell ${!product.id ? 'empty-cell' : ''}`}>
                                                {product.id ?

                                                    <div className='inner-item-cell-wrapper'>
                                                        
                                                        <div
                                                            ref={ref}
                                                            style={{
                                                                height: `${size}px`,
                                                                width: '100%',
                                                                backgroundImage: `url(${product.images[0].src})`,
                                                                backgroundSize: 'cover', // Ensure the image covers the full area
                                                                backgroundPosition: 'center', // Center the image within the element
                                                                backgroundRepeat: 'no-repeat', // Prevent the image from repeating
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => handleSelectItem(product)}
                                                            className='shop-item-img-holder'
                                                        ></div>

                                                        

                                                        <div className='products-first-row'>
                                                            <h3 className='products-header-h3'>{product.name}</h3>

                                                        </div>

                                                        <div className='products-second-row'>
                                                            {product.stock_quantity <= 0
                                                                ? <span className='nina'>V PRIHODU - MOŽNOST PREDNAROČILA</span>
                                                                : product.stock_quantity < 10
                                                                    ? <span className='jena'>Na zalogi <span className='sana'> samo še {product.stock_quantity}</span></span>
                                                                    : <span className='jena'></span>
                                                            }
                                                        </div>

                                                        <div className='underlined-button'>
                                                            <a onClick={() => handleSelectItem(product)}>PREBERI VEČ</a>
                                                        </div>
                                                        <div className='products-first-row'>
                                                            <button className='main-button shop-items-button' onClick={() => handleAddToCart(product)}>V KOŠARICO</button>
                                                            <div className='products-price-div'>
                                                                {product.sale_price === ""
                                                                    ? <span className='regular-price'>{parseFloat(product.regular_price).toFixed(2)}€</span>
                                                                    : (
                                                                        <>
                                                                            <span className='sale-price'>{parseFloat(product.sale_price).toFixed(2)}€</span>
                                                                            <span className='regular-price strike-through'>{parseFloat(product.regular_price).toFixed(2)}€</span>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>



                                                    </div>

                                                    : null}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                </div>
            </div>
            <div className={`alert-circle ${showAlert ? 'show-alert' : ''}`} onClick={hideAlert}>
                {alertMessage}
            </div>
        </div>
    )
}

export default Shop
