import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Navbar from '../../Components/Navbar/Navbar';
import './CategoryView.css'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedItem } from '../../Store/actions/itemActions';
import { setProducts } from '../../Store/actions/productActions';
import { useCookies } from 'react-cookie'
import CookieConsent from '../../Cookies/CookieConsent';

const CategoryView = () => {
    const currentCategory = useSelector(state => state.category.currentCategory);
    const products = useSelector(state => state.products.products); // Get products from Redux
    const [filteredProducts, setFilteredProducts] = useState([]); // State for products filtered by category
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const [cookies] = useCookies(["cookieConsent"]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    

    const handleSelectItem = (product) => {
        dispatch(setSelectedItem(product));
        navigate(`/item/${product.id}`);
    };

    useEffect(() => {
        

        axios.get(`${baseUrl}/products`)
            .then(response => {
                dispatch(setProducts(response.data)); // Dispatch action to set products in Redux
            })
            .catch(error => {
                console.error('Error fetching products:', error);
            });
    }, [dispatch,baseUrl]);


    useEffect(() => {
        if (currentCategory) {
            // Filter products by category
            const matchingProducts = products.filter(product =>
                product.categories.some(category => category.name === currentCategory.name)
            );

            // Sort products by SKU
            matchingProducts.sort((a, b) => {
                if (a.sku < b.sku) {
                    return -1;
                }
                if (a.sku > b.sku) {
                    return 1;
                }
                return 0;
            });

            setFilteredProducts(matchingProducts); // Set filtered and sorted products
        }
    }, [products, currentCategory]);

    const isLoading = useSelector(state => state.loading);

    if (!isLoading.products) {
      return <div className='loading-div-absolute'>
        <div className="lds-ripple"><div></div><div></div></div>
        <p className='loading-paragraph'>Pripravljamo vse potrebno za prvi zagon</p>
      </div>; // Your loading screen/component here
    }

    return (
        <div>
            <Navbar />
            {!cookies.cookieConsent && <CookieConsent />}

            <div className='category-main'>
                <div className='category-wrapper'>
                    <h1 className='main-header category-header'>{currentCategory ? currentCategory.name : 'Category'}</h1>
                    <p className='main-paragraph'>{currentCategory ? currentCategory.description : 'Description'}</p>

                    <div className='items-wrapper'>


                        {filteredProducts.map((product) => {
                            // Remove <p></p> tags and split the string at "|"
                            const descriptionParts = product.description.replace(/<\/?p>/g, '').split('|');
                            const firstPartOfDescription = descriptionParts[0];

                            return (
                                <div key={product.id} className="product-item-cell">
                                    <div className='item-cell-wrapper' onClick={() => handleSelectItem(product)}>
                                        <div>
                                            <img src={product.images[0].src} className='product-img' alt={product.name}/>
                                            <div className='product-text-wrapper'>
                                                <h2 className='product-name'>{product.name}</h2>
                                                <p className='product-paragraph'>{firstPartOfDescription}</p>

                                            </div>
                                        </div>

                                        <button className='main-button item-cell-button'>IZBERI</button>

                                    </div>
                                </div>
                            );
                        })}
                    </div>


                </div>

            </div>
        </div>
    );
};

export default CategoryView;