import React, { useState, useEffect } from 'react';


import './Carousel.css'
import x1 from '../../../Resources/Icons/1.webp'
import x2 from '../../../Resources/Icons/2.webp'
import x3 from '../../../Resources/Icons/3.webp'
import x4 from '../../../Resources/Icons/4.webp'
import x5 from '../../../Resources/Icons/5.webp'
import x6 from '../../../Resources/Icons/6.webp'
import x7 from '../../../Resources/Icons/7.webp'
import x8 from '../../../Resources/Icons/8.webp'


const images = [
    { src: x1, text: "Vidni rezultati po 3 tednih" },
    { src: x2, text: "Klinično preverjen izdelek" },
    // ... similarly for other images
    { src: x3, text: "Možnost recikliranja embalaže" },
    { src: x4, text: "Optimalna doza hidroliziranega kolagena in hranil" },
    { src: x5, text: "Inovativna formula" },
    { src: x6, text: "Visoka kakovost sestavin" },
    { src: x7, text: "Tekoča oblika, okusna in enostavna za pitje" },
    { src: x8, text: "Čista etiketa" }
];



const Carousel = () => {
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 4000); // 4000ms for each image, adjust as needed

        return () => clearInterval(timer);
    }, []);

    return (
        <div className='carousel-main'>
            <div className='carousel-wrapper'>
                <h2 className='best-main-header margin-header'>NAŠ ZLATI STANDARD</h2>

                <div className='show-item' key={currentImage}>
                    <img
                        src={images[currentImage].src}
                        alt='Carousel'
                        className='carousel-image'
                        key={currentImage}
                    />
                    <p className='carousel-text'>{images[currentImage].text}</p>
                </div>

            </div>

            
        </div>
    )
}

export default Carousel
