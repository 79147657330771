import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Components/Navbar/Navbar';
import './Completion.css'
import Footer from '../Components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { clearCart } from '../Store/actions/cartActions';
import { useCookies } from 'react-cookie'
import CookieConsent from '../Cookies/CookieConsent';
import { setCoupon } from '../Store/actions/couponActions'; // Adjust path as necessary

const Completion = () => {
    const cartItems = useSelector(state => state.cart.cartItems); // Accessing cart data from Redux store
    const orderPlacedRef = useRef(false); // Ref to track if order has been placed
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const customerReduxData = useSelector(state => state.customer.billing); // Renamed variable
    const shipping = useSelector(state => state.shipping); // Access shipping data from Redux store
    const [shippingCost, setShippingCost] = useState(0);
    const [shippingCostLoaded, setShippingCostLoaded] = useState(false); // New state variable
    const dispatch = useDispatch();
    const [cookies] = useCookies(["cookieConsent"]);
    const coupon = useSelector(state => state.coupon.coupon);

    const handlehomeClick = () => {
        window.scrollTo(0, 0);
        navigate(`/`);
    };

    useEffect(() => {
        console.log("kupon " + coupon)
        console.log(coupon)

    }, [coupon]);

    useEffect(() => {
        if (shipping.zones.length > 0) {
            // Assuming you want to use the first method of the second zone
            const specificShippingCost = shipping.zones[1]?.methods[1]?.settings?.cost?.value || '0';
            
            setShippingCost(specificShippingCost); // Round to nearest integer
            setShippingCostLoaded(true); // Indicate that the shipping cost has been loaded

        }
    }, [shipping]);

    useEffect(() => {
        if (cartItems.length > 0 && shippingCostLoaded && !orderPlacedRef.current) {
            const customerData = { // This is now a separate variable
                billing: {
                    first_name: customerReduxData.first_name,
                    last_name: customerReduxData.last_name,
                    address_1: customerReduxData.address_1,
                    address_2: '',
                    city: customerReduxData.city,
                    state: 'SI',
                    postcode: customerReduxData.postcode,
                    country: 'SI',
                    email: customerReduxData.email,
                    phone: customerReduxData.phone
                },
                shipping: {
                    first_name: customerReduxData.first_name,
                    last_name: customerReduxData.last_name,
                    address_1: customerReduxData.address_1,
                    address_2: '',
                    city: customerReduxData.city,
                    state: 'SI',
                    postcode: customerReduxData.postcode,
                    country: 'SI'
                }
            };

            const orderData = {
                payment_method: customerReduxData.payment_method,
                payment_method_title: customerReduxData.payment_method_title,
                set_paid: true,
                billing: customerData.billing,
                shipping: customerData.shipping,
                line_items: cartItems.map(item => ({
                    product_id: item.id,
                    quantity: item.quantity
                })),
                shipping_lines: [
                    {
                        method_id: "flat_rate", // Assuming flat rate, change as needed
                        method_title: "Flat Rate Shipping",
                        total: shippingCost.toString()
                    }
                ],
                ...(coupon &&  {
                    coupon_lines: [{
                        code: coupon.code, // Assuming `coupon` is the coupon code. Adjust if your state structure is different
                    }]
                }),
                status: "processing"
                // Add any additional order properties here
            };
    

            axios.post(`${baseUrl}/create-order`, orderData)
                .then(response => {
                    
                    dispatch(clearCart());
                    dispatch(setCoupon(null)); // Dispatch the action to set the coupon in the Redux store

                })
                .catch(error => {
                    console.error('Error creating order:', error);
                    // Handle errors
                });



        }
    }, [cartItems, baseUrl, customerReduxData, shippingCostLoaded, coupon])


    return (
        <div>
            <Navbar />
            {!cookies.cookieConsent && <CookieConsent />}

            <div className='completion-main'>
                <div className='completion-wrapper'>
                    <h1 className='main-header'>HVALA ZA VAŠ NAKUP</h1>
                    <p className='main-paragraph'>Na E-naslov boste prejeli potrdilo naročila, če tega ne prejmete po nekaj minutah nas prosim kontaktirajte.</p>
                    <button className='main-button completion-button' onClick={handlehomeClick}>NAZAJ</button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Completion
