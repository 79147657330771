import { SET_COUPON } from '../actions/couponActions';

const initialState = {
  coupon: null,
};

const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUPON:
      return {
        ...state,
        coupon: action.payload,
      };
    default:
      return state;
  }
};

export default couponReducer;