import React, { useEffect, useRef, useState } from 'react';
import './Blog.css'
import { useDispatch, useSelector } from 'react-redux';
import { setPosts } from '../../Store/actions/postActions';
import Navbar from '../../Components/Navbar/Navbar';
import axios from 'axios';
import Footer from '../../Components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { setSelectedPost } from '../../Store/actions/selectedPostAction';
import { Helmet } from 'react-helmet-async';
import { useCookies } from 'react-cookie'
import CookieConsent from '../../Cookies/CookieConsent';

const Blog = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const posts = useSelector(state => state.posts.posts); // Access posts from Redux store
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [loadedImages, setLoadedImages] = useState({}); // State to store image URLs
    const [size, setSize] = useState(0);
    const ref = useRef(null);
    const media = useSelector(state => state.media.media); // Access posts from Redux store
    const [cookies] = useCookies(["cookieConsent"]);

    const handlePostClick = (post) => {
        window.scrollTo(0, 0);

        dispatch(setSelectedPost(post));

    
        // Pass the post ID (or any other necessary data) to the BlogPost component
        navigate(`/blog/blog-post/${post.id}`);
    };

    
    const updateSize = () => {
        if (ref.current) {
            setSize(ref.current.offsetWidth); // Set height equal to the width
        }
    };

    useEffect(() => {
        updateSize(); // Set initial size
        window.addEventListener('resize', updateSize); // Update size on resize

        return () => window.removeEventListener('resize', updateSize); // Cleanup
    }, []);

    // Function to fetch an image URL by ID
    const fetchImageUrl = async (imageId) => {
        try {
            const response = await axios.get(`https://woocommerce.velins.shop/wp-json/wp/v2/media/${imageId}`);
            return response.data.source_url; // Return the source URL of the image
        } catch (error) {
            console.error(`Error fetching image ${imageId}:`, error);
            return ''; // Return empty string on error
        }
    };

    // Fetch images when posts change
    useEffect(() => {
        const loadedImages = {};
    
        posts.forEach(post => {
            const matchingMedia = media.find(item => item.id === post.acf.blog_post_image);
    
            if (matchingMedia) {
                loadedImages[post.id] = matchingMedia.source_url;
            }
        });
    
        setLoadedImages(loadedImages);
    }, [posts, media]);


    useEffect(() => {
        axios.get(`${baseUrl}/wordpress-posts`)
            .then(response => {
                // Sort the posts by blog_post_id before dispatching
                const sortedPosts = response.data.sort((a, b) => {
                    return a.acf.blog_post_id - b.acf.blog_post_id;
                });
                dispatch(setPosts(sortedPosts));
            })
            .catch(error => {
                console.error('Error fetching posts:', error);
            });
    }, [dispatch, baseUrl]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    

    const getAdjustedPosts = () => {
        const itemCount = posts.length;
        let itemsPerRow = 3; // Default items per row

        // Adjust items per row based on screen width
        if (windowWidth < 800) {
            itemsPerRow = 1;
        } else if (windowWidth < 1400) {
            itemsPerRow = 2;
        }

        const emptyCellsNeeded = itemsPerRow - (itemCount % itemsPerRow);
        if (emptyCellsNeeded === itemsPerRow) return posts; // No empty cells needed

        return [...posts, ...Array(emptyCellsNeeded).fill({})];
    };



    useEffect(() => {
        const updateSize = () => {
            if (ref.current) {
                setSize(ref.current.offsetWidth);
            }
        };
    
        // Call updateSize on resize
        window.addEventListener('resize', updateSize);
    
        // Initial call
        updateSize();
    
        // Cleanup
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    
    // Update size when products are loaded
    useEffect(() => {
        if (media.length > 0) {
            updateSize();
        }
    }, [media]);

    const isLoading = useSelector(state => state.loading);

  if (!isLoading.posts && !isLoading.media) {
    return <div className='loading-div-absolute'>
      <div className="lds-ripple"><div></div><div></div></div>
      <p className='loading-paragraph'>Pripravljamo vse potrebno za prvi zagon</p>
    </div>; // Your loading screen/component here
  }
    

    return (
        <div>
             <Helmet>
        <title>Blog</title>
        <meta name="description" content="Odkrijte nasvete za zdravo življenje bogato s kolagenom." />
        <link rel="canonical" href="https://velins.shop/blog" />
            </Helmet>
            <Navbar />
            {!cookies.cookieConsent && <CookieConsent />}

            <div className='blog-main'>
                <div className='blog-wrapper'>
                    <div className='blog-content'>
                        {/*<div className='category-name-holder blog-holder'>
                            <h2 className='category-name-header'>BLOG</h2>
                        </div>*/}

                        <div className='category-items-holder'>
                            {posts.length === 0 ? (
                                <div className='loading-circle'>Loading...</div>
                            ) : (
                                posts.map((post, index) => (
                                    <div key={index} className='blog-post-item' onClick={() => post.id && handlePostClick(post)}>
                                        <div className='inner-item-cell-wrapper'>
                                            <div
                                                ref={ref}
                                                style={{
                                                    height: `${size}px`, // Adjust `size` as needed
                                                    width: '100%',
                                                    backgroundImage: `url(${loadedImages[post.id]})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    backgroundRepeat: 'no-repeat'
                                                }}
                                                className='blog-item-img-holder'
                                            ></div>
                                            <div className='underlined-button'>
                                                <a  >{post.title.rendered}</a>
                                            </div>

                                            <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Blog;