import React, { useEffect, useState } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import './Checkout.css'
import { useSelector } from 'react-redux';
import Payment from '../../Payments/Payment';
import cashIcon from '../../Resources/Icons/cash-icon.png';
import creditCardIcon from '../../Resources/Icons/credit-card-icon.png';
import Footer from '../../Components/Footer/Footer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateCustomerField } from '../../Store/actions/customerActions.js'
import { setShippingData } from '../../Store/actions/shippingActions'; // Import the action
import axios from 'axios';
import { useCookies } from 'react-cookie'
import CookieConsent from '../../Cookies/CookieConsent.js';

const Checkout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cartItems = useSelector(state => state.cart.cartItems);
    const [customerPhone, setCustomerPhone] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerSurname, setCustomerSurname] = useState('');
    const [customerStreet, setCustomerStreet] = useState('');
    const [customerPosta, setCustomerPosta] = useState('');
    const [customerMesto, setCustomerMesto] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerEmailConfirm, setCustomerEmailConfirm] = useState('');
    const [option3Checked, setOption3Checked] = useState(true); // Initial state for the first checkbox
    const [option4Checked, setOption4Checked] = useState(false);
    const [shippingCost, setShippingCost] = useState(0);
    const customerData = useSelector(state => state.customer.billing);
    const [cookies] = useCookies(["cookieConsent"]);
    const coupon = useSelector(state => state.coupon.coupon);


    useEffect(() => {
        console.log("coupon" + coupon)
    }, [coupon, dispatch]);

    const [products, setProducts] = useState([]); // State to store product data
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const shipping = useSelector(state => state.shipping); // Access shipping data from Redux store

    // Fetch shipping zones and update Redux store
    useEffect(() => {
        axios.get(`${baseUrl}/shipping/zones`)
            .then(response => {
                dispatch(setShippingData(response.data));
            })
            .catch(error => {
                console.error('Error fetching shipping zones:', error);
            });
    }, [dispatch, baseUrl]);

    // Set shipping cost based on specific zone and method
    useEffect(() => {
        if (shipping.zones.length > 0) {
            // Assuming you want to use the first method of the second zone
            const specificShippingCost = shipping.zones[1]?.methods[1]?.settings?.cost?.value || '0';

            // Replace comma with dot and convert to a floating-point number, then convert to cents
            const shippingCostInCents = parseFloat(specificShippingCost.replace(',', '.')) * 100;
            setShippingCost(Math.round(shippingCostInCents)); // Round to nearest integer
        }
    }, [shipping]);

    const fetchProductss = async () => {
        try {
            const response = await fetch(`${baseUrl}/products`); // Replace baseUrl with your API endpoint
            const data = await response.json();
            setProducts(data); // Update the products state with the fetched data
            dispatch(setProducts(data));
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };

    useEffect(() => {
        fetchProductss(); // Fetch product data when the component mounts
    }, []);

    const handleCustomerChange = (event) => {
        setCustomerPhone(event.target.value);
        dispatch(updateCustomerField('phone', event.target.value));
    };

    const handleNameChange = (event) => {
        setCustomerName(event.target.value);
        dispatch(updateCustomerField('first_name', event.target.value));

    };

    const handleSurnameChange = (event) => {
        setCustomerSurname(event.target.value);
        dispatch(updateCustomerField('last_name', event.target.value));

    };

    const handleStreetChange = (event) => {
        setCustomerStreet(event.target.value);
        dispatch(updateCustomerField('address_1', event.target.value));

    };

    const handleMestoChange = (event) => {
        setCustomerMesto(event.target.value);
        dispatch(updateCustomerField('city', event.target.value));

    };

    const handlePostaChange = (event) => {
        setCustomerPosta(event.target.value);
        dispatch(updateCustomerField('postcode', event.target.value));

    };

    const handleEmailChange = (event) => {
        setCustomerEmail(event.target.value);
        dispatch(updateCustomerField('email', event.target.value));

    };

    const handleEmailConfirmChange = (event) => {
        setCustomerEmailConfirm(event.target.value);
        dispatch(updateCustomerField('confirm_email', event.target.value));

    };

    const handleOption3Change = () => {
        setOption3Checked(true);
        setOption4Checked(false);
        dispatch(updateCustomerField('payment_method', 'cod'));
        dispatch(updateCustomerField('payment_method_title', 'Cash on delivery'));

    };

    const handleOption4Change = () => {
        setOption3Checked(false);
        setOption4Checked(true);
        dispatch(updateCustomerField('payment_method', 'stripe'));
        dispatch(updateCustomerField('payment_method_title', 'Stripe credit card'));
    };

    /*const totalPricee = cartItems.reduce((total, item) => {
        return total + item.price * item.quantity;
    }, 0);*/

    const [totalPricee, setTotalPrice] = useState(0);

    useEffect(() => {
        let total = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
        console.log("total:" + total);
        // Check if a coupon is applied and is not "0"
        if (coupon && coupon.amount !== "0") {
            const discountRate = parseFloat(coupon.amount) / 100;
            total = total * (1 - discountRate); // Apply discount
            console.log(total);
            console.log("disko");
            console.log(discountRate);

        }

        setTotalPrice(total);
    }, [cartItems, coupon]);


    useEffect(() => {
        setOption3Checked(true);
        setOption4Checked(false);
        dispatch(updateCustomerField('payment_method', 'cod'));
        dispatch(updateCustomerField('payment_method_title', 'Cash on delivery'));
    }, [])

    const [errorMessage, setErrorMessage] = useState('');


    const handlePurchaseClick = async () => {
        const isValid = await validateCartItems();
        const { isValid: isValidData, message } = await validateCustomerData(customerData);

        if (!isValidData) {
            // Set the error message in your state to display in the component
            // For example: setError(message);
            setErrorMessage(message); // Set the error message

            console.error(message);
            return; // Stop further execution if validation fails
        }

        if (isValid && isValidData) {
            window.scrollTo(0, 0);
            navigate('/completion');
        }
    };


    const validateCustomerData = async (customerData) => {
        // List of required fields
        const requiredFields = [
            'first_name', 'last_name', 'address_1', 'city', 'postcode', 'phone', 'email'
        ];

        // Check if all required fields are filled
        for (const field of requiredFields) {
            if (!customerData[field] || customerData[field].trim() === '') {
                return { isValid: false, message: `Please fill in your ${field.replace('_', ' ')}.` };
            }
        }

        // Check if email and confirm email match
        if (customerData.email !== customerData.confirm_email) {
            return { isValid: false, message: "Email and confirm email do not match." };
        }

        // If all checks pass
        return { isValid: true, message: '' };
    };

    const fetchProducts = async () => {
        try {
            const response = await fetch(`${baseUrl}/products`);
            const data = await response.json();
            return data; // Return the fetched data
        } catch (error) {
            console.error('Error fetching product data:', error);
            return []; // Return empty array in case of an error
        }
    };

    const validateCartItems = async () => {
        const latestProducts = await fetchProducts(); // Get the latest products data

        for (const cartItem of cartItems) {
            const product = latestProducts.find(p => p.id === cartItem.id);
            if (product && cartItem.quantity > product.stock_quantity) {
                navigate('/cart'); // Navigate back to cart
                return false;
            }
        }
        return true;
    };

    const downloadFile = (filename) => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/docs/${filename}`;
        link.download = filename;
        link.click();
    };

    return (
        <div>
            <Navbar />
            {!cookies.cookieConsent && <CookieConsent />}

            <div className='checkout-main'>


                <div className='checkout-wrapper'>
                    <h1 className='main-header'>BLAGAJNA</h1>
                    <div className='checkout-divider'>
                        <div className='checkout-left'>
                            <div className='customer-data-container'>
                                <h2 className='customers-header'>PLAČNIK</h2>

                                <div className='input-row'>
                                    <div className='input-container2'>
                                        <div className='custom-wrapper1'>
                                            <div className='label-wrapper1'>
                                                <label htmlFor='customerName'>IME *</label>
                                            </div>
                                            <input
                                                type='text'
                                                id='customerName'
                                                autoComplete='given-name'
                                                value={customerName}
                                                onChange={handleNameChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className='input-container2'>
                                        <div className='custom-wrapper2'>
                                            <div className='label-wrapper2'>
                                                <label htmlFor='customerName'>PRIIMEK *</label>
                                            </div>
                                            <input
                                                type='text'
                                                id='customerSurname'
                                                value={customerSurname}
                                                onChange={handleSurnameChange}
                                                required
                                                autoComplete='family-name'
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='input-container'>
                                    <label htmlFor='customerName'>TELEFON *</label>
                                    <input
                                        type='tel'
                                        id='customerPhone'
                                        autoComplete='tel'
                                        value={customerPhone}
                                        onChange={handleCustomerChange}
                                        required
                                    />
                                </div>

                                <div className='input-container'>
                                    <label htmlFor='customerName'>ULICA IN HIŠNA ŠTEVILKA*</label>
                                    <input
                                        type='text'
                                        id='customerName'
                                        value={customerStreet}
                                        onChange={handleStreetChange}
                                        required
                                        autoComplete='street-address'
                                    />
                                </div>

                                <div className='input-row'>
                                    <div className='input-container2'>
                                        <div className='custom-wrapper1'>
                                            <div className='label-wrapper1'>
                                                <label htmlFor='customerName'>POŠTNA ŠTEVILKA *</label>
                                            </div>
                                            <input
                                                type='text'
                                                id='customerName'
                                                value={customerPosta}
                                                onChange={handlePostaChange}
                                                required
                                                autoComplete='postal-code'
                                            />
                                        </div>
                                    </div>

                                    <div className='input-container2'>
                                        <div className='custom-wrapper2'>
                                            <div className='label-wrapper2'>
                                                <label htmlFor='customerName'>MESTO *</label>
                                            </div>
                                            <input
                                                type='text'
                                                id='customerName'
                                                value={customerMesto}
                                                onChange={handleMestoChange}
                                                required
                                                autoComplete='address-level2'
                                            />
                                        </div>
                                    </div>
                                </div>



                                <div className='input-container'>
                                    <label htmlFor='customerName'>E-NASLOV *</label>
                                    <input
                                        type='text'
                                        id='customerName'
                                        value={customerEmail}
                                        onChange={handleEmailChange}
                                        required
                                        autoComplete='email'
                                    />
                                </div>

                                <div className='input-container'>
                                    <label htmlFor='customerName'>POTRDI E-NASLOV *</label>
                                    <input
                                        type='text'
                                        id='customerName'
                                        value={customerEmailConfirm}
                                        onChange={handleEmailConfirmChange}
                                        required
                                        autoComplete='email'
                                    />
                                </div>


                            </div>
                        </div>
                        <div className='vertical-divider'></div>
                        <div className='checkout-right'>

                            <div className='narocilo-wrapper'>
                                <h2 className='customers-header header2'>NAROČILO</h2>
                                <p className='dostava-paragraph'>Način plačila</p>

                                <div className='dostava-option'>
                                    <input
                                        type='checkbox'
                                        id='dostavaOption1'
                                        checked={option3Checked}
                                        onChange={handleOption3Change}
                                    />
                                    <div className='dostava-icon-wrapper'>
                                        <img src={cashIcon} alt='dostava' className='dostava-icon' />
                                    </div>
                                    <p className='dostava-option-paragraph'>Plačilo po povzetju</p>
                                </div>

                                <div className={`dostava-option ${option4Checked ? '' : ''}`}>
                                    <input
                                        type='checkbox'
                                        id='dostavaOption1'
                                        checked={option4Checked}
                                        onChange={handleOption4Change}
                                    />
                                    <div className='dostava-icon-wrapper'>
                                        <img src={creditCardIcon} alt='dostava' className='dostava-icon' />
                                    </div>
                                    <div className='credit-card-option'>
                                        <p className='dostava-option-paragraph'>Kreditna kartica</p>
                                    </div>
                                </div>

                                <div className='checkout-horizontal-divider'></div>

                                <div className='partial-znesek'>
                                    <p className='parial-paragraph'>Delni znesek</p>
                                    <div className='final-price2'>{totalPricee.toFixed(2)}€</div>

                                </div>

                                

                                <div className='partial-znesek'>
                                    <p className='parial-paragraph'>Dostava</p>
                                    <div className='final-price2'>{(shippingCost / 100).toFixed(2)}€</div>

                                </div>

                                <div className='checkout-horizontal-divider2'></div>

                                <div className='partial-znesek'>
                                    <p className='parial-paragraph3'>Znesek</p>
                                    <div className='final-price3'>{(totalPricee + (shippingCost / 100)).toFixed(2)}€</div>

                                </div>

                                <p className='pogoji-paragraph'>S potrditvijo naročila se strinjate s <span className='span-pogoj' onClick={() => downloadFile('splosni_pogoji_poslovanja.docx')}> pogoji poslovanja</span></p>

                                {option3Checked === true ? (
                                    <>
                                        <button className='main-button' onClick={handlePurchaseClick}>POTRDI</button>
                                        {errorMessage && <div className="error-message">{errorMessage}</div>}

                                    </>) : (
                                    <Payment />
                                )}

                            </div>

                        </div>
                    </div>
                </div>

                {/*<Payment/>*/}
            </div>

            <Footer />
        </div>
    )
}

export default Checkout
