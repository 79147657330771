import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './Navigations/Main/Main';
import { Provider  } from 'react-redux';
import { store, persistor } from './Store'; // Update the path as needed
import CategoryView from './Navigations/Category/CategoryView';
import ItemView from './Navigations/Item/ItemView';
import Cart from './Navigations/Cart/Cart';
import { PersistGate } from 'redux-persist/integration/react';
import Checkout from './Navigations/Checkout/Checkout';
import Completion from './Payments/Completion';

import DataFetcher from './DataFetcher'; // Import the new component
import Shop from './Navigations/Shop/Shop';
import Blog from './Navigations/Blog/Blog';
import BlogPost from './Navigations/BlogPost/BlogPost';
import About from './Navigations/About/About';
import Aboutt from './Navigations/About/About';
import { useSelector } from 'react-redux';
import Nagradna from './Navigations/Nagradna/Nagradna';


function App() {
  
  

  return ( 
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <DataFetcher />
        <Router>
          <Routes>
            <Route path="/" element={<Main />} />
            {/*<Route path="/nagradna-igra" element={<Nagradna />} />*/}
            <Route path="/category/:categoryName" element={<CategoryView />} />
            <Route path="/item/:itemId" element={<ItemView />} />
            <Route path="/Cart" element={<Cart />} />
            <Route path="/Checkout" element={<Checkout />} />
            <Route path="/completion" element={<Completion />} />
            <Route path="/Shop" element={<Shop />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/blog/blog-post/:postId" element={<BlogPost />} />
            <Route path="/About" element={<Aboutt />} />

          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
