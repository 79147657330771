import React from 'react'
import './About.css'
import love from '../../../Resources/Images/AdobeStock.webp'

const About = () => {
  return (
    <div className='about-main'>
        <div className='about-wrapper'>
            <div className='about-cell'>
                <img className='love-img' alt='love' src={love}/>
            </div>
            <div className='about-cell'>
                <div className='about-content-wrapper'>
                    <h2 className='about-header'>
                        Naše poslanstvo
                    </h2>
                    <p className='about-paragraph'>
                    Ljudem pomagamo, da se dlje časa počutijo in izgledajo bolje. Zavezani smo k strokovnemu
znanju in odličnosti. Poganja nas inovativnost in podpira znanost. Poslušamo, razumemo,
vodimo, da bi spodbudili lepoto in dobro počutje od znotraj navzven.
                    </p>
                    <div className='underlined-button'>
                        <a href="/About">PREBERI VEČ</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About
