import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Navbar from '../../Components/Navbar/Navbar';
import './ItemView.css'
import { useDispatch } from 'react-redux';
import { addToCart } from '../../Store/actions/cartActions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css';
import X from '../../Resources/Icons/X.svg'
import x1 from '../../Resources/Icons/1.webp'
import x2 from '../../Resources/Icons/2.webp'
import x3 from '../../Resources/Icons/3.webp'
import x4 from '../../Resources/Icons/4.webp'
import x5 from '../../Resources/Icons/5.webp'
import x6 from '../../Resources/Icons/6.webp'
import x7 from '../../Resources/Icons/7.webp'
import x8 from '../../Resources/Icons/8.webp'
import woman from '../../Resources/Images/wom.webp'
import Footer from '../../Components/Footer/Footer';
import Items from '../Main/Components/Items';
import { setItemsDetails } from '../../Store/actions/itemsDetailsActions'; // Ensure you have this action created
import axios from 'axios';
import w1 from '../../Resources/Images/w1.webp'
import w2 from '../../Resources/Images/w2.webp'
import w3 from '../../Resources/Images/w3.webp'
import w4 from '../../Resources/Images/w4.webp'
import w5 from '../../Resources/Images/w5.webp'
import BlogCarousel from '../Main/Components/BlogCarousel';
import { useParams } from 'react-router-dom';
import { setSelectedItem } from '../../Store/actions/itemActions'; // Ensure you have this action created
import { useCookies } from 'react-cookie'
import CookieConsent from '../../Cookies/CookieConsent';


const images = [
  { src: x1, text: "Vidni rezultati po 3 tednih" },
  { src: x2, text: "Klinično preverjen izdelek" },
  // ... similarly for other images
  { src: x3, text: "Možnost recikliranja embalaže" },
  { src: x4, text: "Optimalna doza hidroliziranega kolagena in hranil" },
  { src: x5, text: "Inovativna formula" },
  { src: x6, text: "Visoka kakovost sestavin" },
  { src: x7, text: "Tekoča oblika, okusna in enostavna za pitje" },
  { src: x8, text: "Čista etiketa" }
];



const ItemView = () => {
  const selectedItem = useSelector(state => state.item.selectedItem);
  const itemsDetails = useSelector(state => state.itemsDetails.itemsDetails);
  const media = useSelector(state => state.media.media); // Access posts from Redux store
  const [selectedDetails, setSelectedDetails] = useState(null);
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [isFaq1Clicked, setIsFaq1Clicked] = useState(false);
  const [isFaq2Clicked, setIsFaq2Clicked] = useState(false);
  const [isFaq3Clicked, setIsFaq3Clicked] = useState(false);
  const [isFaq4Clicked, setIsFaq4Clicked] = useState(false);
  const [isFaq5Clicked, setIsFaq5Clicked] = useState(false);
  const [isFaq7Clicked, setIsFaq7Clicked] = useState(false);

  const [isFaq6Clicked, setIsFaq6Clicked] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const products = useSelector(state => state.products.products);
  const { itemId } = useParams(); // Assuming your route parameter is named 'itemId'
  const [cookies] = useCookies(["cookieConsent"]);

  const getMediaLinkById = (id) => {
    const mediaItem = media.find(item => item.id === id);
    return mediaItem ? mediaItem.link : '';
  };

  useEffect(() => {
    // Find the item in the products reducer with the same ID
    const foundItem = products.find(item => item.id.toString() === itemId);
    if (foundItem) {
      // Dispatch action to set selectedItem
      dispatch(setSelectedItem(foundItem)); // Replace with your action to set the selected item
    }
  }, [itemId, products, dispatch]);

  const handleFaq1Click = () => {
    setIsFaq1Clicked(!isFaq1Clicked);
  };

  const handleFaq2Click = () => {
    setIsFaq2Clicked(!isFaq2Clicked);
  };

  const handleFaq3Click = () => {
    setIsFaq3Clicked(!isFaq3Clicked);
  };

  const handleFaq4Click = () => {
    setIsFaq4Clicked(!isFaq4Clicked);
  };

  const handleFaq5Click = () => {
    setIsFaq5Clicked(!isFaq5Clicked);
  };

  const handleFaq7Click = () => {
    setIsFaq7Clicked(!isFaq7Clicked);
  };

  const handleFaq6Click = () => {
    setIsFaq6Clicked(!isFaq6Clicked);
  };

  const handleAddToCart = () => {
    if (selectedItem.stock_quantity > 0) {
      dispatch(addToCart({
        ...selectedItem,
        quantity: 1 // Assuming you start with a quantity of 1, modify as needed
      }));

      setAlertMessage("Produkt dodan");
      setShowAlert(true);
    } else {
      // Optionally, you can handle the case where the selectedItem is out of stock
      // For example, display a message to the user
      setAlertMessage("Ni na zalogi");
      setShowAlert(true);
    }

    setTimeout(() => {
      hideAlert();
    }, 1000);
  };

  const hideAlert = () => {
    setAlertMessage("");
    setShowAlert(false);
  };



  useEffect(() => {
    // Check if selectedItem exists and is not null
    if (selectedItem) {
      const details = itemsDetails.find(detail => detail.title.rendered === selectedItem.name);
      setSelectedDetails(details);
    }
  }, [selectedItem, itemsDetails]);


  useEffect(() => {
    // Check if selectedItem exists and is not null
    console.log(selectedDetails);
  }, [selectedDetails]);

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 4000); // 4000ms for each image, adjust as needed

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    axios.get(`${baseUrl}/item-details`)
      .then(response => {
        dispatch(setItemsDetails(response.data));
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, [dispatch, baseUrl]);

  let bulletPoints;
  if (selectedDetails && selectedDetails.acf && selectedDetails.acf.bullets) {
    bulletPoints = selectedDetails.acf.bullets.split('|').map((bullet, index) => (
      <div key={index} className='check-div-row'>
        <img src={X} />
        <p className='check-paraf'>{bullet.trim()}</p>
      </div>
    ));
  } else {
    // Handle the case where selectedDetails or selectedDetails.acf is not available
    bulletPoints = <p>Loading...</p>; // Or any other placeholder you prefer
  }

  let faqContent;
  if (selectedDetails && selectedDetails.acf && selectedDetails.acf.detajli && selectedDetails.acf.detajli !== "") {
    const detajliItems = selectedDetails.acf.detajli.split('|').map((item, index) => {
      if (item.includes(';')) {
        // Further splitting and creating check-div-row for each sub-item
        return item.split(';').map((subItem, subIndex) => (
          <div key={`subitem-${subIndex}`} className='check-div-row hidden-rows'>
            <img src={X} />
            <p className='check-paraf'>{subItem.trim()}</p>
          </div>
        ));
      } else {
        // Directly returning the item as a paragraph
        return <p key={index} className='faq-p'>{item.trim()}</p>;
      }
    });


    faqContent = (
      <div className={`faq ${isFaq1Clicked ? 'show-all' : ''}`} onClick={handleFaq1Click}>
        <div className='thefqwrapper'>
          <h2 className='faq-h'>PODROBNOSTI O IZDELKU</h2>
          {detajliItems}
        </div>
      </div>
    );
  } else {
    // Handle the case where acf.detajli is empty or not available
    faqContent = null; // or any other placeholder you prefer
  }


  let faqContent2;
  if (selectedDetails && selectedDetails.acf && selectedDetails.acf.uporaba && selectedDetails.acf.uporaba !== "") {
    const detajliItems = selectedDetails.acf.uporaba.split('|').map((item, index) => {
      if (item.includes(';')) {
        // Further splitting and creating check-div-row for each sub-item
        return item.split(';').map((subItem, subIndex) => (
          <div key={`subitem-${subIndex}`} className='check-div-row hidden-rows'>
            <img src={X} />
            <p className='check-paraf'>{subItem.trim()}</p>
          </div>
        ));
      } else {
        // Directly returning the item as a paragraph
        return <p key={index} className='faq-p'>{item.trim()}</p>;
      }
    });


    faqContent2 = (
      <div className={`faq ${isFaq2Clicked ? 'show-all' : ''}`} onClick={handleFaq2Click}>
        <div className='thefqwrapper'>
          <h2 className='faq-h'>NAVODILA ZA UPORABO</h2>
          {detajliItems}
        </div>
      </div>
    );
  } else {
    // Handle the case where acf.detajli is empty or not available
    faqContent2 = null; // or any other placeholder you prefer
  }

  let sestavineContent;
  if (selectedDetails && selectedDetails.acf && selectedDetails.acf.sestavine && selectedDetails.acf.sestavine !== "") {
    const sestavineItems = selectedDetails.acf.sestavine.split('|').map((item, index) => {
      const parts = item.split(';');
      const firstPartBold = parts.length > 0 ? <strong>{parts[0].trim()}</strong> : null;
      const otherParts = parts.slice(1).map((part, partIndex) => <span key={`part-${partIndex}`}>{part.trim()}</span>);

      return (
        <div key={index} className='sestavine-div'>
          {firstPartBold} {otherParts}
        </div>
      );
    });

    sestavineContent = (
      <div className={`faq ${isFaq3Clicked ? 'show-all' : ''}`} onClick={handleFaq3Click}>
        <div className='thefqwrapper'>
          <h2 className='faq-h'>UČINKI SESTAVIN</h2>
          {sestavineItems}
        </div>
      </div>
    );
  } else {
    // Handle the case where acf.sestavine is empty or not available
    sestavineContent = null; // or any other placeholder you prefer
  }


  let hranilnaVrednostContent;
  if (selectedDetails && selectedDetails.acf && selectedDetails.acf.hranilna_vrednost && selectedDetails.acf.hranilna_vrednost !== "") {
    const nutritionalValues = selectedDetails.acf.hranilna_vrednost.split('|').map((value, index) => {
      const parts = value.split(':');
      const leftPart = parts.length > 0 ? <p>{parts[0].trim()}</p> : null;
      const rightPart = parts.length > 1 ? <p>{parts[1].trim()}</p> : null;

      return (
        <div key={index} className='nutritional-value-div' style={{ display: 'flex', justifyContent: 'space-between' }}>
          {leftPart}
          {rightPart}
        </div>
      );
    });

    hranilnaVrednostContent = (
      <div className={`faq ${isFaq4Clicked ? 'show-all' : ''}`} onClick={handleFaq4Click}>
        <div className='thefqwrapper'>
          <h2 className='faq-h'>HRANILNA VREDNOST</h2>
          {nutritionalValues}
        </div>
      </div>
    );
  } else {
    // Handle the case where acf.hranilna_vrednost is empty or not available
    hranilnaVrednostContent = null; // or any other placeholder you prefer
  }




  let faqContent3;
  if (selectedDetails && selectedDetails.acf && selectedDetails.acf.sestavine_in_ukrepi && selectedDetails.acf.sestavine_in_ukrepi !== "") {
    const detajliItems = selectedDetails.acf.sestavine_in_ukrepi.split('|').map((item, index) => {
      if (item.includes(';')) {
        // Further splitting and creating check-div-row for each sub-item
        return item.split(';').map((subItem, subIndex) => (
          <div key={`subitem-${subIndex}`} className='check-div-row hidden-rows'>
            <img src={X} />
            <p className='check-paraf'>{subItem.trim()}</p>
          </div>
        ));
      } else {
        // Directly returning the item as a paragraph
        return <p key={index} className='faq-p'>{item.trim()}</p>;
      }
    });


    faqContent3 = (
      <div className={`faq ${isFaq5Clicked ? 'show-all' : ''}`} onClick={handleFaq5Click}>
        <div className='thefqwrapper'>
          <h2 className='faq-h'>SESTAVINE</h2>
          {detajliItems}
        </div>
      </div>
    );
  } else {
    // Handle the case where acf.detajli is empty or not available
    faqContent3 = null; // or any other placeholder you prefer
  }

  let faqContent35;
  if (selectedDetails && selectedDetails.acf && selectedDetails.acf.ukrepi && selectedDetails.acf.ukrepi !== "") {
    const detajliItems = selectedDetails.acf.ukrepi.split('|').map((item, index) => {
      if (item.includes(';')) {
        // Further splitting and creating check-div-row for each sub-item
        return item.split(';').map((subItem, subIndex) => (
          <div key={`subitem-${subIndex}`} className='check-div-row hidden-rows'>
            <img src={X} />
            <p className='check-paraf'>{subItem.trim()}</p>
          </div>
        ));
      } else {
        // Directly returning the item as a paragraph
        return <p key={index} className='faq-p'>{item.trim()}</p>;
      }
    });


    faqContent35 = (
      <div className={`faq ${isFaq7Clicked ? 'show-all' : ''}`} onClick={handleFaq7Click}>
        <div className='thefqwrapper'>
          <h2 className='faq-h'>OPOZORILA</h2>
          {detajliItems}
        </div>
      </div>
    );
  } else {
    // Handle the case where acf.detajli is empty or not available
    faqContent35 = null; // or any other placeholder you prefer
  }



  let faqContent4;
  if (selectedDetails && selectedDetails.acf && selectedDetails.acf.ideje && selectedDetails.acf.ideje !== "") {
    const detajliItems = selectedDetails.acf.ideje.split('|').map((item, index) => {
      if (item.includes(';')) {
        // Further splitting and creating check-div-row for each sub-item
        return item.split(';').map((subItem, subIndex) => (
          <div key={`subitem-${subIndex}`} className='check-div-row hidden-rows'>
            <img src={X} />
            <p className='check-paraf'>{subItem.trim()}</p>
          </div>
        ));
      } else {
        // Directly returning the item as a paragraph
        return <p key={index} className='faq-p'>{item.trim()}</p>;
      }
    });


    faqContent4 = (
      <div className={`faq ${isFaq6Clicked ? 'show-all' : ''}`} onClick={handleFaq6Click}>
        <div className='thefqwrapper'>
          <h2 className='faq-h'>IDEJA ZA RECEPT</h2>
          {detajliItems}
        </div>
      </div>
    );
  } else {
    // Handle the case where acf.detajli is empty or not available
    faqContent4 = null; // or any other placeholder you prefer
  }


  let images2;

  if (selectedDetails && selectedDetails.acf && selectedDetails.acf['3weeks']) {
    const acfData = selectedDetails.acf['start'];
    const acfData2 = selectedDetails.acf['3weeks'];
    const acfData3 = selectedDetails.acf['6weeks'];
    const acfData4 = selectedDetails.acf['9weeks'];
    const acfData5 = selectedDetails.acf['6monts'];

    if (acfData !== "") {
      const splitTexts = acfData.split('|');
      const text1 = splitTexts.length > 0 ? splitTexts[0] : "";
      const text2 = splitTexts.length > 1 ? splitTexts[1] : "";

      const splitTexts2 = acfData2.split('|');
      const text12 = splitTexts2.length > 0 ? splitTexts2[0] : "";
      const text22 = splitTexts2.length > 1 ? splitTexts2[1] : "";

      const splitTexts3 = acfData3.split('|');
      const text13 = splitTexts3?.length > 0 ? splitTexts3[0] : "";
      const text23 = splitTexts3?.length > 1 ? splitTexts3[1] : "";

      const splitTexts4 = acfData4.split('|');
      const text14 = splitTexts4?.length > 0 ? splitTexts4[0] : "";
      const text24 = splitTexts4?.length > 1 ? splitTexts4[1] : "";

      const splitTexts5 = acfData5.split('|');
      const text15 = splitTexts5?.length > 0 ? splitTexts5[0] : "";
      const text25 = splitTexts5?.length > 1 ? splitTexts5[1] : "";

      images2 = [
        { src: w1, text1: text1, text2: text2, text3: "ZAČETEK" },
        { src: w2, text1: text12, text2: text22, text3: "PO 3 TEDNIH" },
        { src: w3, text1: text13, text2: text23, text3: "PO 6 TEDNIH" },
        { src: w4, text1: text14, text2: text24, text3: "PO 9 TEDNIH" },
        { src: w5, text1: text15, text2: text25, text3: "PO 6 MESECIH" },
      ];
    } else {
      // Handle the case where acf.3weeks is not a non-empty string
      images2 = []; // Or any other default you prefer
    }
  } else {
    // Handle the case where selectedDetails or selectedDetails.acf or selectedDetails.acf['3weeks'] is not available
    images2 = []; // Or any other default you prefer
  }

  const [currentImagee, setCurrentImagee] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImagee((prevImage) => (prevImage + 1) % 5);
    }, 4000); // 4000ms for each image, adjust as needed

    return () => clearInterval(timer);
  }, []);

  const isLoading = useSelector(state => state.loading);

  if (!isLoading.products && !isLoading.itemDetails && !isLoading.media) {
    return <div className='loading-div-absolute'>
      <div className="lds-ripple"><div></div><div></div></div>
      <p className='loading-paragraph'>Pripravljamo vse potrebno za prvi zagon</p>
    </div>; // Your loading screen/component here
  }

  return (
    <div>
      <Navbar />
      {!cookies.cookieConsent && <CookieConsent />}

      <div className='item-view-main'>
        {selectedDetails && (
          <div className='this-item-desktop'>
            <div className='selected-item-row selected-item-row-desktop'>
              <div className='selected-right-hidden my-custom-swiper'>

                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0} // Adjust the space between slides
                  slidesPerView={1} // Show 2 items per page
                  pagination={{ clickable: true }}
                >
                  {selectedItem.images.map((image, index) => (
                    index !== 0 && ( // Exclude the first image
                      <SwiperSlide key={index}>
                        <img src={image.src} className='selected-item-img' alt={selectedItem.name} />
                      </SwiperSlide>
                    )
                  ))}
                </Swiper>


              </div>
              <div className='selected-left'>
                <div className='order-cell-wrapper'>
                  <h1 className='selected-items-header'>{selectedDetails.title.rendered}</h1>
                  <p className='items-first-paragraph'>{selectedDetails.acf.podnaslov}</p>
                  <p className='items-second-paragraph'>{selectedDetails.acf.podparagraf}</p>

                  <div className='checks-div'>
                    {bulletPoints}
                  </div>

                  <div className='products-second-row ninamargin'>
                    {selectedItem.stock_quantity <= 0
                      ? <span className='nina'>V PRIHODU - MOŽNOST PREDNAROČILA</span>
                      : selectedItem.stock_quantity < 10
                        ? <span className='jena'>Na zalogi <span className='sana'> samo še {selectedItem.stock_quantity}</span></span>
                        : <span className='jena'></span>
                    }
                  </div>

                  <div className='big-price'>
                    {selectedItem.sale_price !== "" &&
                      <div className='price-sale'>{parseFloat(selectedItem.regular_price).toFixed(2)}€</div>
                    }
                    <div className='price-big'>{parseFloat(selectedItem.price).toFixed(2)}€</div>
                  </div>

                  <button className='button-hug' onClick={handleAddToCart}>V KOŠARICO</button>



                </div>
              </div>
              <div className='selected-right my-custom-swiper'>

                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0} // Adjust the space between slides
                  slidesPerView={1} // Show 2 items per page
                  pagination={{ clickable: true }}
                >
                  {selectedItem.images.map((image, index) => (
                    index !== 0 && ( // Exclude the first image
                      <SwiperSlide key={index}>
                        <img src={image.src} className='selected-item-img' alt={selectedItem.name} />
                      </SwiperSlide>
                    )
                  ))}
                </Swiper>


              </div>


            </div>

            <div className='super-name'>
              <div className='name-super-big'>{selectedItem.name}</div>
            </div>





            <div className='FAQ'>
              <div className='faq-left'>
                <div className='faq-wrapper'>

                  {faqContent}


                  {faqContent2}


                  {sestavineContent}

                  {hranilnaVrednostContent}

                  {faqContent3}

                  {faqContent35}

                  {faqContent4}
                </div>
              </div>

            </div>

            {selectedDetails && selectedDetails.acf && selectedDetails.acf['3weeks'] ? (
              <div className='carousel-main ctx-main'>
                <div className='carousel-wrapper'>

                  <div className='show-item' key={currentImagee}>
                    <img
                      src={images2[currentImagee].src}
                      alt='Carousel'
                      className='carousel-image detailed-carousel'
                      key={currentImagee}
                    />
                    <p className='carousel-text ctx0'>{images2[currentImagee].text3}</p>
                    <p className='carousel-text ctx1'>{images2[currentImagee].text1}</p>
                    <p className='carousel-text ctx2'>{images2[currentImagee].text2}</p>
                  </div>

                </div>
              </div>
            ) : null}


            {selectedDetails && selectedDetails.acf && (selectedDetails.acf.uporaba1 || selectedDetails.acf.uporaba2) && (
              <div className='uporaba-images-main'>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true, }}

                >
                  {selectedDetails.acf.uporaba1 && (
                    <SwiperSlide>
                      <div className='uporaba-item'>
                        <img src={getMediaLinkById(selectedDetails.acf.uporaba1)} alt='Uporaba 1' className='uporaba-image' />

                      </div>
                    </SwiperSlide>
                  )}
                  {selectedDetails.acf.uporaba2 && (
                    <SwiperSlide>
                      <div className='uporaba-item'>
                        <img src={getMediaLinkById(selectedDetails.acf.uporaba2)} alt='Uporaba 1' className='uporaba-image' />

                      </div>
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>
            )}


            <Items />
            <BlogCarousel />
            <Footer />
            <div className={`alert-circle ${showAlert ? 'show-alert' : ''}`} onClick={hideAlert}>
              {alertMessage}
            </div>
          </div>

        )}
      </div>

      <div className={`alert-circle ${showAlert ? 'show-alert' : ''}`} onClick={hideAlert}>
        {alertMessage}
      </div>

    </div>
  );
};

export default ItemView;