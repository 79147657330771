import React, { useState, useEffect } from 'react'
import axios from 'axios';
import bag from '../../Resources/Icons/shopping-bag.png'
import fb from '../../Resources/Icons/fb.png'
import ig from '../../Resources/Icons/ig.png'
import './Navbar.css'
import logo from '../../Resources/Images/logo.jpg'
import { useDispatch } from 'react-redux';
import { setCategories } from '../../Store/actions/categoryActions';
import { setCurrentCategory } from '../../Store/actions/currentCategoryActions';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const categories = useSelector(state => state.categories.categories); // Get categories from Redux
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const cartItems = useSelector(state => state.cart.cartItems); // Accessing cart data from Redux store
    const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const [isContactVisible, setIsContactVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    // ... existing functions ...

    // Event handler for email input
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    // Event handler for message textarea
    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const toggleContact = () => {
        setIsContactVisible(!isContactVisible);
    };

    const toggleContactMobi = () => {
        setIsMenuOpen(false)
        setIsContactVisible(!isContactVisible);
    };

    const toggleMenu = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(!isMenuOpen);
        }
        else {
            setIsMenuOpen(!isMenuOpen);
        }
    };

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCategoryClick = (category) => {
        window.scrollTo(0, 0);

        dispatch(setCurrentCategory(category));
        navigate(`/category/${category.name}`);
    };

    const handleCategoryClickMobile = (category) => {
        window.scrollTo(0, 0);

        dispatch(setCurrentCategory(category));
        toggleDropdown();
        toggleMenu();
        navigate(`/category/${category.name}`);

    };

    const handleLogoClick = () => {
        window.scrollTo(0, 0);

        navigate(`/`);
    };

    const handleCartClick = () => {
        window.scrollTo(0, 0);

        navigate(`/Cart`);
    };


    const handleShopClick = () => {
        window.scrollTo(0, 0);

        navigate(`/Shop`);
    };

    const handleShopMobileClick = () => {
        window.scrollTo(0, 0);

        toggleMenu();

        navigate(`/Shop`);
    };

    const handleaboutClick = () => {
        window.scrollTo(0, 0);

        navigate(`/About`);
    };

    const handleaboutMobileClick = () => {
        window.scrollTo(0, 0);

        toggleMenu();

        navigate(`/About`);
    };

    const handleBlogClick = () => {
        window.scrollTo(0, 0);


        navigate(`/blog`);
    };

    const handleBlogMobileClick = () => {
        window.scrollTo(0, 0);

        toggleMenu();

        navigate(`/blog`);
    };



    const handleNagradnaClick = () => {
        window.scrollTo(0, 0);

        navigate(`/nagradna-igra`);
    };

    const handleNagradnaMobileClick = () => {
        window.scrollTo(0, 0);

        toggleMenu();

        navigate(`/nagradna-igra`);
    };

    useEffect(() => {
        axios.get(`${baseUrl}/categories`)
            .then(response => {
                const sortedCategories = [...response.data].sort((a, b) => a.slug.localeCompare(b.slug));
            dispatch(setCategories(sortedCategories));
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
    }, [dispatch, baseUrl]);


    const sendMessage = async () => {
        try {
            const response = await fetch(`${baseUrl}/send-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email, message: message })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            
            setAlertMessage("Sporočilo poslano");
            setShowAlert(true);
            toggleContact();
            // Handle success (e.g., showing a confirmation message)
        } catch (error) {
            console.error("Error sending message:", error.message);
            setAlertMessage("Napaka pri pošiljanju sporočila");
            setShowAlert(true);
            toggleContact();
            // Handle errors (e.g., showing an error message)
        }

        setTimeout(() => {
            hideAlert();
        }, 1000);
    };

    const hideAlert = () => {
        setAlertMessage("");
        setShowAlert(false);
    };


    function redirectToFacebook() {
        window.open("https://www.facebook.com/profile.php?id=61556167204275", "_blank");
      }

      function redirectToInstagram() {
        window.open("https://www.instagram.com/velins.shop/", "_blank");
      }

    return (
        <>
        <div className='navbar-main'>

            

            <div className='navbar-row'>
                <div className='empty-wrapper'>
                    <div className='hidden-logo-wrapper'>
                        <img alt='logo' className='logo-img' src={logo} onClick={handleLogoClick} />
                    </div>

                    <div className='navigation-item'>
                        <p className='navigation' onClick={handleShopClick}>TRGOVINA</p>

                    </div>

                    <p className='navigation' onClick={handleaboutClick}>O NAS</p>
                    <p className='navigation' onClick={handleBlogClick}>NOVIČKE</p>
                    {/*<p className='navigation' onClick={handleNagradnaClick}>NAGRADNA IGRA</p>*/}
                </div>
                <div className='navigations-wrapper'>

                    <div className='logo-wrapper'>
                        <img alt='logo' className='logo-img' src={logo} onClick={handleLogoClick} />
                    </div>
                </div>
                <div className='icons-wrapper'>

                    

                    <div className='icon-bag-wrapper' onClick={handleCartClick}>
                        <img alt='bag' className='shopping-bag-icon' src={bag} />
                        <div className='cart-count'>{totalQuantity}</div> {/* Display total quantity */}
                    </div>

                    
                    <button className='navbar-button' onClick={toggleContact}>KONTAKT</button>

                    <div className='social-wrapper'>
                        <img alt='bag' className='shopping-bag-icon' src={fb} onClick={redirectToFacebook}/>
                        <img alt='bag' className='shopping-bag-icon igbag' src={ig} onClick={redirectToInstagram}/>

                    </div>

                    <div className='menu-icon' onClick={toggleMenu}>
                        <div className={`menu-bar ${isMenuOpen ? 'bar1' : ''}`}></div>
                        <div className={`menu-bar ${isMenuOpen ? 'bar2' : ''}`}></div>
                    </div>
                </div>
            </div>

            <div className={`hidden-menu ${isMenuOpen ? 'show-menu' : ''}`}>

                <div className='navigationsopen-wraper'>
                    <div className='nav-open-wrapper' onClick={handleShopMobileClick}>
                        <p className='navigationopen' onClick={handleShopMobileClick}>TRGOVINA</p>
                        <div className='arrow-right'></div>

                    </div>

                    <div className='nav-open-wrapper' onClick={handleaboutMobileClick}>
                        <p className='navigationopen' onClick={handleaboutMobileClick}>O NAS</p>
                        <div className='arrow-right'></div>
                    </div>
                    <div className='nav-open-wrapper' onClick={handleBlogMobileClick}>
                        <p className='navigationopen' onClick={handleBlogMobileClick}>NOVIČKE</p>
                        <div className='arrow-right'></div>
                    </div>
                    <div className='nav-open-wrapper' onClick={toggleContactMobi}>
                        <p className='navigationopen' onClick={toggleContactMobi}>KONTAKT</p>
                        <div className='arrow-right'></div>
                    </div>
                    {/*<div className='nav-open-wrapper' onClick={handleNagradnaMobileClick}>
                        <p className='navigationopen' onClick={handleNagradnaMobileClick}>NAGRADNA IGRA</p>
                        <div className='arrow-right'></div>
                    </div>*/}
                </div>

                <div className='social-wrapper showonbile'>
                        <img alt='bag' className='shopping-bag-icon bigbag' src={fb} onClick={redirectToFacebook}/>
                        <img alt='bag' className='shopping-bag-icon igbag bigbag' src={ig} onClick={redirectToInstagram}/>

                </div>
            </div>


            <div className={`kontakt-hidden-div ${isContactVisible ? 'slide-in' : 'slide-out'}`}>
                <div className='contact-first-row-please'>
                    <div className='menu-icon4' onClick={toggleContact}>
                        <div className="menu-bar4 bar3 whitebar speed3"></div>
                        <div className="menu-bar4 bar4 whitebar speed4"></div>
                    </div>
                </div>

                <div className='input-container2 clasinput'>
                    <div className='custom-wrapper2'>
                        <div className='label-wrapper2 lwr'>
                            <label htmlFor='customerName'>E-NASLOV *</label>
                        </div>
                        <input
                            type='text'
                            id='customerSurname'
                            className='lwr'
                            required
                            autoComplete='email'
                            value={email} // Bind state variable
                            onChange={handleEmailChange} 
                        />
                    </div>
                </div>
                <div className='input-container2 clasinput thisarea'>
                    <div className='custom-wrapper2'>
                        <div className='label-wrapper2 lwr'>
                            <label htmlFor='customerEmail'>SPOROČILO *</label>
                        </div>
                        <textarea
                            id='customerEmail'
                            required
                            autoComplete='message'
                            rows="4"  // Number of rows (adjust as needed)
                            cols="50" // Number of columns (adjust as needed)
                            value={message} // Bind state variable
                        onChange={handleMessageChange}
                        ></textarea>
                    </div>
                </div>

                <div className='last-row'>
                <button className='main-button' onClick={sendMessage}>
                    POŠLJI
                </button>

                <div className='footer-right-column margintopcontact'>
                        <p className='column-right-header'>KONTAKT</p>
                        <p className='column-right-paragraph' >+386 1 292 6041</p>
                        <p className='column-right-paragraph'>info@velins.shop</p>
                        <p className='column-right-paragraph'>Leskoškova cesta 9e Ljubljana</p>
                    </div>
                </div>
            </div>


            


        </div>
        <div className={`alert-circle navbar-alert ${showAlert ? 'show-alert' : ''}`} onClick={hideAlert}>
                {alertMessage}
            </div>
        </>
    )
}

export default Navbar
