import React, { useEffect } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Hero from './Components/Hero'
import Tribulet from './Components/Tribulet'
import About from './Components/About'
import Best from './Components/Best'
import Carousel from './Components/Carousel'
import Grid from './Components/Grid'
import Media from './Components/Media'
import Items from './Components/Items'
import Footer from '../../Components/Footer/Footer'
import BlogCarousel from './Components/BlogCarousel'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { setLoading } from '../../Store/actions/loadingActions'
import { useDispatch } from 'react-redux';
import './Main.css'
import CookieConsent from '../../Cookies/CookieConsent'
import { useCookies } from 'react-cookie'

const Main = () => {

  
  const [cookies] = useCookies(["cookieConsent"]);

  const isLoading = useSelector(state => state.loading);

  if (!isLoading.products) {
    return <div className='loading-div-absolute'>
      <div className="lds-ripple"><div></div><div></div></div>
      <p className='loading-paragraph'>Pripravljamo vse potrebno za prvi zagon</p>
    </div>; // Your loading screen/component here
  }

  return (
    <div>
      <Helmet>
        <title>Velins Shop - prodaja izdelkov kolagena</title>
        <meta name="description" content="Odkrijte vrhunski kolagen za sijočo kožo in dobro počutje v Velins.shop. Z našimi dodatki negujte lepoto od znotraj!" />
        <link rel="canonical" href="https://velins.shop/" />
      </Helmet>
      <Navbar/>
      <Hero/>
      {/*<Tribulet/>*/}
      <About/>
      <Best/>
      <Carousel/>
      <Grid/>
      <Media/>
      <Items/>
      <BlogCarousel/>
      <Footer/>
      {!cookies.cookieConsent && <CookieConsent />}
    </div>
  )
}

export default Main
