import React, { useState } from 'react'
import './Footer.css'
import { useNavigate } from 'react-router-dom';
import fb from '../../Resources/Icons/fb.png'
import ig from '../../Resources/Icons/ig.png'

const Footer = () => {
    const [email, setEmail] = useState(''); // State for storing email
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const hideAlert = () => {
        setAlertMessage("");
        setShowAlert(false);
    };

    const navigate = useNavigate();

    const handleShopClick = (category) => {
        window.scrollTo(0, 0);
        navigate(`/Shop`, { state: { category } });
    };

    const handleaboutClick = () => {
        window.scrollTo(0, 0);

        navigate(`/About`);
    };

    const handleBlogClick = () => {
        window.scrollTo(0, 0);


        navigate(`/blog`);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    // Optional: Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        const createCustomerUrl = `${baseUrl}/create-woocommerce-customer`; // Update the endpoint URL

        try {
            const response = await fetch(createCustomerUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }) // Send only the email
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            setAlertMessage("Naročnina uspešna");
            setShowAlert(true);
            setEmail('')

            // Handle the response data as needed
        } catch (error) {
            console.error("Error creating customer:", error.message);
            setAlertMessage("Naročnina uspešna");
            setShowAlert(true);
            setEmail('')
            // Handle errors
        }

        setTimeout(() => {
            hideAlert();
        }, 1000);
    };

    const downloadFile = (filename) => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/docs/${filename}`;
        link.download = filename;
        link.click();
    };

    function redirectToFacebook() {
        window.open("https://www.facebook.com/profile.php?id=61556167204275", "_blank");
    }

    function redirectToInstagram() {
        window.open("https://www.instagram.com/velins.shop/", "_blank");
    }

    return (
        <>
            <div className='footer-main'>
                <div className='footer-wrapper'>

                    <div className='footer-desktop'>
                        <div className='desktop-left'>
                            <p className='footer-header'>ARTENIA D.O.O.</p>
                            <p className='policy-small'>Z registracijo se strinjate s prejemanjem naših novic. Izjavljate, da ste starejši od 18 let, ste prebrali pravilnik o zasebnosti in dovoljujete obdelavo vaših podatkov v marketinške namene.</p>



                        </div>

                        <div className='desktop-mid'>
                            <div className='social-wrapperfoot'>
                                <img alt='bag' className='shopping-bag-icon bigfoot' src={fb} onClick={redirectToFacebook} />
                                <img alt='bag' className='shopping-bag-icon igbag bigfoot' src={ig} onClick={redirectToInstagram} />

                            </div>
                        </div>

                        <div className='desktop-right'>

                            <div className='footer-right-column'>
                                <p className='column-right-header'>INFORMACIJE</p>
                                <p className='column-right-paragraph' onClick={handleaboutClick}>O nas</p>
                                <p className='column-right-paragraph' onClick={handleBlogClick}>Blog</p>
                                <p className='column-right-paragraph' onClick={() => downloadFile('splosni_pogoji_poslovanja.pdf')}>Splošni pogoji poslovanja</p>
                                <p className='column-right-paragraph' onClick={() => downloadFile('pravilnik_o_zasebnosti.pdf')}>Pravilnik o zasebnosti</p>
                                <p className='column-right-paragraph' onClick={() => downloadFile('obvestilo_o_odstopu_od_pogodbe.pdf')}>Obvestilo o odstopu od pogodbe</p>
                                <p className='column-right-paragraph' onClick={() => downloadFile('pogoji_nagradne_igre.pdf')}>Pogoji za sodelovanje v nagradni igri</p>


                            </div>
                            <div className='footer-right-column'>
                                <p className='column-right-header'>KONTAKT</p>
                                <p className='column-right-paragraph' >+386 1 292 6041</p>
                                <p className='column-right-paragraph'>info@velins.shop</p>
                                <p className='column-right-paragraph'>Leskoškova cesta 9e</p>
                                <p className='column-right-paragraph'>1000 Ljubljana</p>

                            </div>

                            <div className='footer-right-column'>
                                <p className='column-right-header'>TRGOVINA</p>
                                <p className='column-right-paragraph' onClick={() => handleShopClick(17)}>ZDRAVJE</p>
                                <p className='column-right-paragraph' onClick={() => handleShopClick(18)}>LASJE</p>
                                <p className='column-right-paragraph' onClick={() => handleShopClick(20)}>VITALNOST</p>
                                <p className='column-right-paragraph' onClick={() => handleShopClick(19)}>NEGA</p>
                            </div>


                        </div>
                    </div>

                    <div className='footer-mobile'>
                        <p className='footer-header'>ARTENIA D.O.O.</p>
                        <p className='policy-small'>Z registracijo se strinjate s prejemanjem naših novic. Izjavljate, da ste starejši od 18 let, ste prebrali pravilnik o zasebnosti in dovoljujete obdelavo vaših podatkov v marketinške namene.</p>
                        <div className='footer-right-column'>
                            <p className='column-right-header'>INFORMACIJE</p>
                            <p className='column-right-paragraph' onClick={handleaboutClick}>O nas</p>
                            <p className='column-right-paragraph' onClick={handleBlogClick}>Blog</p>
                            <p className='column-right-paragraph' onClick={() => downloadFile('splosni_pogoji_poslovanja.pdf')}>Splošni pogoji poslovanja</p>
                            <p className='column-right-paragraph' onClick={() => downloadFile('pravilnik_o_zasebnosti.pdf')}>Pravilnik o zasebnosti</p>
                            <p className='column-right-paragraph' onClick={() => downloadFile('obvestilo_o_odstopu_od_pogodbe.pdf')}>Obvestilo o odstopu od pogodbe</p>
                            <p className='column-right-paragraph' onClick={() => downloadFile('pogoji_nagradne_igre.pdf')}>Pogoji za sodelovanje v nagradni igri</p>

                        </div>
                        <div className='footer-right-column'>
                            <p className='column-right-header'>KONTAKT</p>
                            <p className='column-right-paragraph' >+386 1 292 6041</p>
                            <p className='column-right-paragraph'>info@velins.shop</p>
                            <p className='column-right-paragraph'>Leskoškova cesta 9e</p>
                            <p className='column-right-paragraph'>1000 Ljubljana</p>
                        </div>

                        <div className='footer-right-column'>
                            <p className='column-right-header'>TRGOVINA</p>
                            <p className='column-right-paragraph' onClick={() => handleShopClick(17)}>ZDRAVJE</p>
                            <p className='column-right-paragraph' onClick={() => handleShopClick(18)}>LASJE</p>
                            <p className='column-right-paragraph' onClick={() => handleShopClick(20)}>VITALNOST</p>
                            <p className='column-right-paragraph' onClick={() => handleShopClick(19)}>NEGA</p>
                        </div>

                    </div>

                </div>
                <div className='footer-subscribe'>

                    <div className='social-wrapperfoot mobilefoot'>
                        <img alt='bag' className='shopping-bag-icon bigfoot' src={fb} onClick={redirectToFacebook} />
                        <img alt='bag' className='shopping-bag-icon igbag bigfoot' src={ig} onClick={redirectToInstagram} />

                    </div>

                    <div className='footer-input-wrapper'>
                        <h2 className='subscribers-header'>NAROČITE SE NA NOVICE</h2>
                        <form onSubmit={handleSubmit}> {/* Optional form element */}
                            <input
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                className="email-input"
                                placeholder="Vnesite vaš e-naslov"
                            />
                            {/* Optional submit button */}
                            <button className='main-button' type="submit">NAROČI SE</button>
                        </form>
                    </div>
                </div>
                <div className='footer-black'>
                    <p className='black-paragraph'></p>
                    <p className='black-paragraph'>© ARTENIA D.O.O. Vse pravice pridržane</p>
                    <p className='black-paragraph'></p>
                </div>
            </div>
            <div className={`alert-circle navbar-alert ${showAlert ? 'show-alert' : ''}`} onClick={hideAlert}>
                {alertMessage}
            </div>
        </>
    )
}

export default Footer
