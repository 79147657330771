import React from 'react'
import './Bubbles.css'
import combined from '../../../Resources/Images/combined3.webp'
import hyaluronic from '../../../Resources/Images/Feature1.webp'
import pure from '../../../Resources/Images/feature4.webp'
import gold from '../../../Resources/Images/feature5.webp'
import night from '../../../Resources/Images/feature6.webp'
import mosf from '../../../Resources/Images/feature7.webp'
import spray from '../../../Resources/Images/feature8.webp'
import kids from '../../../Resources/Images/feature9.webp'
import after from '../../../Resources/Images/feature10.webp'

import { useNavigate } from 'react-router-dom';

const Bubbles = () => {

  const navigate = useNavigate();

  const handleItemClick = (path) => {
    window.scrollTo(0, 0);

    navigate(path);
  };

  return (
    <div className='bubble-main'>
      <div className='bubble-wrapper'>

        <div className='bubbles'>
          {/*<img alt='combined-img' src={combined} className='combined-image' />*/}
          <div className='combined-img'>
            <img alt='combined-img' src={hyaluronic} className='featue-image' onClick={() => handleItemClick('/item/220')}  />
            <img alt='combined-img-absolut' src={pure} className='featue-image-abs1'  onClick={() => handleItemClick('/item/30')} />
            <img alt='combined-img-absolut' src={gold} className='featue-image-abs2' onClick={() => handleItemClick('/item/26')}/>
            <img alt='combined-img-absolut' src={night} className='featue-image-abs3' onClick={() => handleItemClick('/item/66')}/>
            <img alt='combined-img-absolut' src={mosf} className='featue-image-abs4' onClick={() => handleItemClick('/item/448')}/>
            <img alt='combined-img-absolut' src={spray} className='featue-image-abs5' onClick={() => handleItemClick('/item/452')}/>
            <img alt='combined-img-absolut' src={kids} className='featue-image-abs6' onClick={() => handleItemClick('/item/462')}/>
            <img alt='combined-img-absolut' src={after} className='featue-image-abs7' onClick={() => handleItemClick('/item/466')}/>

          </div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
        </div>
      </div>
    </div>
  )
}

export default Bubbles
