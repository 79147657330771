import React from 'react'
import './Media.css'
import media1 from '../../../Resources/Images/media1.webp'
import media2 from '../../../Resources/Images/media2.webp'
import img1 from '../../../Resources/Media/1.png'
import img2 from '../../../Resources/Media/2.png'
import img3 from '../../../Resources/Media/3.png'
import img4 from '../../../Resources/Media/4.png'
import img5 from '../../../Resources/Media/5.png'
import img6 from '../../../Resources/Media/6.png'
import img7 from '../../../Resources/Media/7.png'
import img8 from '../../../Resources/Media/8.png'

import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const mediaLinks = [
  { img: img1, link: 'https://www.hellomagazine.com/healthandbeauty/health-and-fitness/504698/gold-collagen-can-help-relieve-menopause-symptoms/' },
  { img: img2, link: 'https://www.whowhatwear.co.uk/gold-collagen-supplements' },
  { img: img3, link: 'https://www.marieclaire.co.uk/beauty/gold-collagen' },
  { img: img4, link: 'https://www.vogue.co.uk/beauty/article/best-collagen-supplements' },
  { img: img5, link: 'https://aeworld.com/beauty/skincare/this-is-why-you-need-to-invest-in-gold-collagen-for-your-skin/' },
  { img: img6, link: 'https://www.goodhousekeeping.com/uk/beauty/skincare/g43138026/best-collagen-supplements/' },
  { img: img7, link: 'https://onaplus.delo.si/stil/lepota/odkrijte-skrivnost-vecne-lepote-novi-izdelek-za-zenske-nad-40-let/' },
  { img: img8, link: 'https://www.fashionavenue.si/post/gold-collagen-velins-shop' },
];

const Media = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 7500, // Duration of the transition in milliseconds
    slidesToShow: 3, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: true,
    autoplaySpeed: 0, // Transition to the next slide immediately
    cssEase: "linear", // Ensures a smooth transition
    rtl: true, // Move items from right to left
    pauseOnHover: false, // Prevents pausing when the mouse hovers over the carousel
  };


  return (
    <div className='media-main'>
        <div className='media-wrapper'>
            <h2 className='best-main-header margin-header'>NAŠI PRODUKTI SO PREDSTAVLJENI IN SVETOVANI V SLOVENSKIH IN TUJIH MEDIJIH
            </h2>
            {/*<img className='media-img show-media' src={media1} alt='medij'/>
            <img className='media-img hide-media' src={media2} alt='medij'/>*/}


            <Slider {...settings}>
          {mediaLinks.map(({ img, link }, index) => (
            <div key={index} className='caro-holder'>
              <a href={link} target="_blank" rel="noopener noreferrer" className='the-medias-link'>
                <img className='media-img' src={img} alt='medij'/>
              </a>
            </div>
          ))}
        </Slider>
        </div>
    </div>
  )
}

export default Media
