// src/store/reducers/shippingReducer.js
import { SET_SHIPPING_DATA } from '../actions/shippingActions';

const initialState = {
    zones: [] // Only zones are needed here
};

const shippingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHIPPING_DATA:
            return {
                ...state,
                zones: action.payload // The payload will directly be the zones data
            };
        default:
            return state;
    }
};

export default shippingReducer;