// src/store/actions/customerActions.js
export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';
export const UPDATE_CUSTOMER_FIELD = 'UPDATE_CUSTOMER_FIELD';

export const setCustomerData = (data) => ({
    type: SET_CUSTOMER_DATA,
    payload: data
});


export const updateCustomerField = (field, value) => ({
    type: UPDATE_CUSTOMER_FIELD,
    payload: { field, value }
});
