import React, { useState, useEffect } from 'react';
import './Items.css'

import c1 from '../../../Resources/Images/c1.webp'
import c2 from '../../../Resources/Images/c2.webp'
import c3 from '../../../Resources/Images/c3.webp'
import c4 from '../../../Resources/Images/c4.webp'
import c5 from '../../../Resources/Images/c5.webp'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';

import 'swiper/css';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedItem } from '../../../Store/actions/itemActions';


const Items = () => {
    const products = useSelector(state => state.products.products); // Access products from Redux store
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [filteredProducts, setFilteredProducts] = useState([]); // State for filtered products

    const handleSelectItem = (product) => {
        window.scrollTo(0, 0);

        dispatch(setSelectedItem(product));
        navigate(`/item/${product.id}`);
    };

    useEffect(() => {
        // Filter products with stock_quantity > 0 and update state
        const availableProducts = products.filter(product => product.stock_quantity > 0);
        setFilteredProducts(availableProducts);
    }, [products]);

    return (
        <div className='items-main super-carousel-main'>
            <div className='items-wrapper'>
            <div className='desktop-items'>
            <Swiper
                    modules={[Pagination]}
                        spaceBetween={20} // Adjust the space between slides
                        slidesPerView={5} // Show 2 items per page
                        pagination={{ clickable: true }}
                    >
                        {filteredProducts.map((product, index) => (
                            <SwiperSlide key={index}>
                                <div className='item-cell-mobile'>
                                    <img src={product.images[0].src} alt={product.name} className='cell-img' />
                                    <p className='cell-item-paragraph'>{product.name}</p>
                                    <div className='cell-item-button' onClick={() => handleSelectItem(product)}>KUPI ZDAJ</div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    </div>
                <div className='mobile-itemss'>
                    <Swiper
                    modules={[Pagination]}
                        spaceBetween={20} // Adjust the space between slides
                        slidesPerView={2} // Show 2 items per page
                        pagination={{ clickable: true }}
                    >
                        {filteredProducts.map((product, index) => (
                            <SwiperSlide key={index}>
                                <div className='item-cell-mobile'>
                                    <img src={product.images[0].src} alt={product.name} className='cell-img' />
                                    <p className='cell-item-paragraph'>{product.name}</p>
                                    <div className='cell-item-button' onClick={() => handleSelectItem(product)}>KUPI ZDAJ</div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

            </div>
        </div>
    )
}

export default Items
