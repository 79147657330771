import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setProducts } from './Store/actions/productActions';
import { setCategories } from './Store/actions/categoryActions';
import { setPosts } from './Store/actions/postActions'; // Ensure you have this action created
import { setItemsDetails } from './Store/actions/itemsDetailsActions'; // Ensure you have this action created
import { setMedia } from './Store/actions/mediaActions'; // Ensure you have this action created
import { setShippingData } from './Store/actions/shippingActions'; // Import the action
import { setLoading, setLoadingProducts, setLoadingCategories, setLoadingPosts, setLoadingItemDetails, setLoadingMedia, setLoadingShipping  } from './Store/actions/loadingActions'; // Import the action

const DataFetcher = () => {
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  {/*useEffect(() => {
    Promise.all([
      axios.get(`${baseUrl}/products`),
      axios.get(`${baseUrl}/categories`),
      axios.get(`${baseUrl}/wordpress-posts`),
      axios.get(`${baseUrl}/item-details`),
      axios.get(`${baseUrl}/media`),
      axios.get(`${baseUrl}/shipping/zones`),
    ]).then(([products, categories, posts, itemDetails, media, shippingZones]) => {
      // Dispatch actions to set data in the store
      dispatch(setProducts(products.data));
      dispatch(setCategories(categories.data));
      dispatch(setPosts(posts.data));
      dispatch(setItemsDetails(itemDetails.data));
      dispatch(setMedia(media.data));
      dispatch(setShippingData(shippingZones.data));

      // Set loading to false once all data is fetched
      dispatch(setLoading(false));
    }).catch(error => {
      console.error('Error fetching data:', error);
      // Optionally handle errors, for example setting loading to false
      dispatch(setLoading(false));
    });
  }, [dispatch, baseUrl]);*/}



  useEffect(() => {
    // Dispatch loading actions for each data type before fetching
    

    Promise.all([
      axios.get(`${baseUrl}/products`).then((products) => {
        dispatch(setProducts(products.data));
        dispatch(setLoadingProducts(true)); // Set loading to false after fetching products
      }).catch(() => dispatch(setLoadingProducts(true))), // Also set loading to false on error

      axios.get(`${baseUrl}/categories`).then((categories) => {
        dispatch(setCategories(categories.data));
        dispatch(setLoadingCategories(true)); // Set loading to false after fetching categories
      }).catch(() => dispatch(setLoadingCategories(true))), // Also set loading to false on error

      axios.get(`${baseUrl}/wordpress-posts`).then((posts) => {
        dispatch(setPosts(posts.data));
        dispatch(setLoadingPosts(true)); // Set loading to false after fetching posts
      }).catch(() => dispatch(setLoadingPosts(true))), // Also set loading to false on error

      axios.get(`${baseUrl}/item-details`).then((itemDetails) => {
        dispatch(setItemsDetails(itemDetails.data));
        dispatch(setLoadingItemDetails(true)); // Set loading to false after fetching item details
      }).catch(() => dispatch(setLoadingItemDetails(true))), // Also set loading to false on error

      axios.get(`${baseUrl}/media`).then((media) => {
        dispatch(setMedia(media.data));
        dispatch(setLoadingMedia(true)); // Set loading to false after fetching media
      }).catch(() => dispatch(setLoadingMedia(true))), // Also set loading to false on error

      axios.get(`${baseUrl}/shipping/zones`).then((shippingZones) => {
        dispatch(setShippingData(shippingZones.data));
        dispatch(setLoadingShipping(true)); // Set loading to false after fetching shipping zones
      }).catch(() => dispatch(setLoadingShipping(true))), // Also set loading to false on error
    ]);
  }, [dispatch, baseUrl]);


  return null; // This component doesn't render anything
};

export default DataFetcher;