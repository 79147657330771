import { PaymentElement } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import './Payment.css'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cartItems = useSelector(state => state.cart.cartItems);
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const shipping = useSelector(state => state.shipping); // Access shipping data from Redux store
    const [shippingCost, setShippingCost] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const customerData = useSelector(state => state.customer.billing);

    useEffect(() => {
        if (shipping.zones.length > 0) {
            // Assuming you want to use the first method of the second zone
            const specificShippingCost = shipping.zones[1]?.methods[1]?.settings?.cost?.value || '0';
            
            // Replace comma with dot and convert to a floating-point number, then convert to cents
            const shippingCostInCents = parseFloat(specificShippingCost.replace(',', '.')) * 100;
            setShippingCost(Math.round(shippingCostInCents)); // Round to nearest integer
        }
    }, [shipping]);

    const fetchProducts = async () => {
        try {
            const response = await fetch(`${baseUrl}/products`);
            const data = await response.json();
            return data; // Return the fetched data
        } catch (error) {
            console.error('Error fetching product data:', error);
            return []; // Return empty array in case of an error
        }
    };
    
    const validateCartItems = async () => {
        const latestProducts = await fetchProducts(); // Get the latest products data
    
        for (const cartItem of cartItems) {
            const product = latestProducts.find(p => p.id === cartItem.id);
            if (product && cartItem.quantity > product.stock_quantity) {
                navigate('/cart'); // Navigate back to cart
                return false;
            }
        }
        return true;
    };

    const validateCustomerData = async (customerData) => {
        // List of required fields
        const requiredFields = [
            'first_name', 'last_name', 'address_1', 'city', 'postcode', 'phone', 'email'
        ];
    
        // Check if all required fields are filled
        for (const field of requiredFields) {
            if (!customerData[field] || customerData[field].trim() === '') {
                return { isValid: false, message: `Please fill in your ${field.replace('_', ' ')}.` };
            }
        }
    
        // Check if email and confirm email match
        if (customerData.email !== customerData.confirm_email) {
            return { isValid: false, message: "Email and confirm email do not match." };
        }
    
        // If all checks pass
        return { isValid: true, message: '' };
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValid = await validateCartItems();
        const { isValid: isValidData, message } = await validateCustomerData(customerData);

        if (!isValidData) {
            // Set the error message in your state to display in the component
            // For example: setError(message);
            setErrorMessage(message); // Set the error message

            console.error(message);
            return; // Stop further execution if validation fails
        }

        if (!isValid) {
            navigate('/cart');
            return; // Exit the handleSubmit function early

        }

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        

        setIsProcessing(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${window.location.origin}/completion`,
            },
        });



        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occured.");
        }

        setIsProcessing(false);
    };




    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <button  type="primary" disabled={isProcessing || !stripe || !elements} id="submit" className="main-button">
                <span id="button-text">
                    {isProcessing ? "Processing ... " : "Pay now"}
                </span>
            </button>
            {/* Show any error or success messages */}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {message && <div id="payment-message">{message}</div>}
        </form>
    )
}

export default CheckoutForm
