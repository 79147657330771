import React, { useEffect, useState } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import './BlogPost.css'
import { useLocation,useParams  } from 'react-router-dom';
import X from '../../Resources/Icons/X.svg'
import { useSelector,useDispatch } from 'react-redux'; // Import useSelector
import { useNavigate } from 'react-router-dom';
import { setSelectedItem } from '../../Store/actions/itemActions';
import Footer from '../../Components/Footer/Footer';
import { setSelectedPost } from '../../Store/actions/selectedPostAction';
import Items from '../Main/Components/Items';
import BlogCarousel from '../Main/Components/BlogCarousel';
import { useCookies } from 'react-cookie'
import CookieConsent from '../../Cookies/CookieConsent';

const BlogPost = () => {
    const location = useLocation();
    //const { post, imageUrl } = location.state;
    const [textSections, setTextSections] = useState([]);
    const products = useSelector(state => state.products.products); // Access products from Redux store
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { postId } = useParams(); // postId will have the value passed in the URL
    const post = useSelector(state => state.selectedPost.selectedPost);
    const [imageUrl, setImgUrl] = useState('');
    const media = useSelector(state => state.media.media);
    const posts = useSelector(state => state.posts.posts);
    const [cookies] = useCookies(["cookieConsent"]);


    useEffect(() => {
        // Find the item in the products reducer with the same ID
        const foundItem = posts.find(item => item.id.toString() === postId);
        if (foundItem) {
          // Dispatch action to set selectedItem
          dispatch(setSelectedPost(foundItem)); // Replace with your action to set the selected item
        }
      }, [postId, posts, dispatch]);


      useEffect(() => {
        // Check if post and media are not null or undefined
        if (post && media) {
            // Find the media item that matches the post's featured_media ID
            const matchedMedia = media.find(m => m.id === post.featured_media);
        
            // If a matching media item is found, set the imageUrl state
            if (matchedMedia) {
                setImgUrl(matchedMedia.source_url);
            }
        }
    }, [post, media]); 

    
    
    

    

    const renderTextSection = (section) => {
        if (section.includes('$')) {
            // Split the section at each "$" and render each part in a new div
            return section.split('$').map((paragraph, index) => (
                <div key={index} className='blog-paragraph bullet-paragraph-cut'>
                    <div className='bullet-holder'><img src={X} /></div>
                    <div className='paragraph-holder-bullet'>{paragraph}</div>
                </div>
            ));
        } else {
            // Render the whole section in a single div if no "$" is present
            return <div className='blog-paragraph'>{section}</div>;
        }
    };
    const [matchingProduct, setMatchingProduct] = useState(null);

    useEffect(() => {
        if (post && post.acf && post.acf.blog_post_item && products.length > 0) {
            const foundProduct = products.find(product => product.name === post.acf.blog_post_item);
            setMatchingProduct(foundProduct);
        }
    }, [post, products]);

    const handleSelectItem = (product) => {
        window.scrollTo(0, 0);

        dispatch(setSelectedItem(product));
        navigate(`/item/${product.id}`);
    };

    useEffect(() => {
        if (post && post.acf) {
            // Split the text by "|" and store it in an array
            const sections = post.acf.blog_post_text.split("|");
            setTextSections(sections);
        }
    }, [post]);

    // Check for post availability before trying to access its properties
    

    const isLoading = useSelector(state => state.loading);

    if (!isLoading.products ||!isLoading.posts ||!isLoading.media || !post || !post.acf || !post.title) {
      return <div className='loading-div-absolute'>
        <div className="lds-ripple"><div></div><div></div></div>
        <p className='loading-paragraph'>Pripravljamo vse potrebno za prvi zagon</p>
      </div>; // Your loading screen/component here
    }


    

    return (
        <div>
            <Navbar />
            {!cookies.cookieConsent && <CookieConsent />}

            <div className='blog-post-main'>
                <div className='category-name-holder blog-holder blogpostholder'>
                    <h2 className='category-name-header'>{post.title.rendered}</h2>
                </div>
                <div className='blog-post-wrapper'>
                    <div className='blog-post-row'>
                        <div className='blog-post-left'>
                            <img src={imageUrl} alt={post.title.rendered} className='post-img' />

                            <div className='desktop-item-in-blog'>
                                {matchingProduct && (
                                    <div className='thisdesktop-wrppae'>
                                        <img src={matchingProduct.images[0].src} alt={matchingProduct.name} className='thispostimg'/>
                                        <p className='thisblog-paragraph-name'>{matchingProduct.name}</p>

                                        <div className='underlined-button thisunderline'>
                                                            <a onClick={() => handleSelectItem(matchingProduct)}>PREBERI VEČ</a>
                                                        </div>
                                        {/* Add more product details as needed */}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='blog-post-right'>
                            <div className='blog-post-wrapper-r'>
                                {textSections.map((section, index) => (
                                    <div key={index} className='blog-section'>
                                        {renderTextSection(section)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='desktop-item-in-blog-mob'>
                                {matchingProduct && (
                                    <div className='thisdesktop-wrppae'>
                                        <img src={matchingProduct.images[0].src} alt={matchingProduct.name} className='thispostimg'/>
                                        <p className='thisblog-paragraph-name'>{matchingProduct.name}</p>

                                        <div className='underlined-button thisunderline'>
                                                            <a onClick={() => handleSelectItem(matchingProduct)}>PREBERI VEČ</a>
                                                        </div>
                                        {/* Add more product details as needed */}
                                    </div>
                                )}
                            </div>
                </div>
            </div>
            <Items/>
            <BlogCarousel/>
            <Footer/>
        </div>
    )
}

export default BlogPost
