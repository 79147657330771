export const setLoading = (isLoading) => ({
    type: 'SET_LOADING',
    payload: isLoading,
});

export const setLoadingProducts = (isLoading) => ({
    type: 'SET_LOADING_PRODUCTS',
    payload: isLoading,
});

export const setLoadingCategories = (isLoading) => ({
    type: 'SET_LOADING_CATEGORIES',
    payload: isLoading,
});

export const setLoadingPosts = (isLoading) => ({
    type: 'SET_LOADING_POSTS',
    payload: isLoading,
});

export const setLoadingItemDetails = (isLoading) => ({
    type: 'SET_LOADING_ITEM_DETAILS',
    payload: isLoading,
});

export const setLoadingMedia = (isLoading) => ({
    type: 'SET_LOADING_MEDIA',
    payload: isLoading,
});

export const setLoadingShipping = (isLoading) => ({
    type: 'SET_LOADING_SHIPPING',
    payload: isLoading,
});