// src/store/reducers/customerReducer.js
import { SET_CUSTOMER_DATA } from '../actions/customerActions';
import { UPDATE_CUSTOMER_FIELD } from '../actions/customerActions';

const initialState = {
    billing: {},
    shipping: {}
};

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CUSTOMER_DATA:
            return {
                ...state,
                billing: action.payload.billing,
                shipping: action.payload.shipping
            };

        case UPDATE_CUSTOMER_FIELD:
            return {
                ...state,
                billing: {
                    ...state.billing,
                    [action.payload.field]: action.payload.value
                }
                // Update shipping similarly if needed
            };

        default:
            return state;
    }
};

export default customerReducer;