import React from 'react'
import './Hero.css'
import Bubbles from './Bubbles'
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navigate = useNavigate();

  const handleShopClick = () => {
    window.scrollTo(0, 0);

    navigate(`/Shop`);
};
  return (
    <div className='hero-main'>
        <div className='hero-wrapper'>
            <h1 className='main-header'>NARAVANI IZDELKI ZA VAŠE DOBRO POČUTJE, LEPOTO IN ZAŠČITO!</h1>
            <p className='main-paragraph'>Izkoristite darila narave in zasijte v svoji naravni lepoti.</p>
            <button className='main-button' onClick={handleShopClick}>RAZIŠČI</button>

            
        </div>
        <Bubbles/>
    </div>
  )
}

export default Hero
