import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import './Payment.css'
import { useSelector } from 'react-redux';

const Payment = () => {
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const cartItems = useSelector(state => state.cart.cartItems); // Accessing cart data from Redux store
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const shipping = useSelector(state => state.shipping); // Access shipping data from Redux store
    const [shippingCost, setShippingCost] = useState();
    const coupon = useSelector(state => state.coupon.coupon);

    useEffect(() => {
        if (shipping.zones.length > 0) {
            // Assuming you want to use the first method of the second zone
            const specificShippingCost = shipping.zones[1]?.methods[1]?.settings?.cost?.value || '0';
            
            // Replace comma with dot and convert to a floating-point number, then convert to cents
            const shippingCostInCents = parseFloat(specificShippingCost.replace(',', '.')) * 100;
            setShippingCost(Math.round(shippingCostInCents)); // Round to nearest integer
        }
    }, [shipping]);


    useEffect(() => {
        fetch(`${baseUrl}/config`).then(async (r) => {
            const { publishableKey } = await r.json();
            setStripePromise(loadStripe(publishableKey));

        });
    }, [baseUrl]);

    useEffect(() => {
        /*const totalPriceInCents = cartItems.reduce((total, item) => {
            // Assuming item.price is in dollars, convert it to cents and multiply by quantity
            return total + (item.price * 100 * item.quantity);
        }, 0);*/

        let totalPrice = cartItems.reduce((total, item) => {
            return total + (item.price * item.quantity);
        }, 0);
    
        // Check if a coupon is applied and its amount is not "0"
        if (coupon !== null) {
            const discountRate = parseFloat(coupon.amount) / 100;
            totalPrice = totalPrice * (1 - discountRate); // Apply discount
        }
    
        // Convert totalPrice to cents for Stripe
        const totalPriceInCents = Math.round(totalPrice * 100);

        const absulutetotal=totalPriceInCents+shippingCost;

        console.log("absolut:" + absulutetotal);

        fetch(`${baseUrl}/create-payment-intent`, {
            method: "POST",
            body: JSON.stringify({ amount: absulutetotal }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(async (result) => {
            const { clientSecret } = await result.json();
            setClientSecret(clientSecret);
        });


        
    }, [cartItems,baseUrl,shippingCost, coupon]);


  return (
    <div className="stripe-form">
            {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <CheckoutForm />
                </Elements>
            )}
        </div>
  )
}

export default Payment
