export const ADD_TO_CART = 'ADD_TO_CART';
export const INCREMENT_QUANTITY = 'INCREMENT_QUANTITY';
export const DECREMENT_QUANTITY = 'DECREMENT_QUANTITY';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';

// Additional actions like REMOVE_FROM_CART can be defined here as well

export const addToCart = item => ({
  type: ADD_TO_CART,
  payload: item
});

export const incrementQuantity = itemId => ({
    type: INCREMENT_QUANTITY,
    payload: itemId
  });
  
  export const decrementQuantity = itemId => ({
    type: DECREMENT_QUANTITY,
    payload: itemId
  });

  export const removeFromCart = itemId => ({
    type: REMOVE_FROM_CART,
    payload: itemId
  });

  export const clearCart = () => ({
    type: CLEAR_CART
  });