import React from 'react'
import './Grid.css'
import zena1 from '../../../Resources/Images/01.webp'
import zena2 from '../../../Resources/Images/02.webp'
import zena3 from '../../../Resources/Images/03.webp'
import zena4 from '../../../Resources/Images/04.webp'
import zena5 from '../../../Resources/Images/5.webp'
import zena6 from '../../../Resources/Images/6.webp'
import { useNavigate } from 'react-router-dom';

const Grid = () => {
    const handleHover = (e, scale) => {
        // Find the closest ancestor that is either .grid-cell or .grid-cell2
        const cell = e.currentTarget.closest('.grid-cell, .grid-cell2');
        const img = cell.querySelector('.zena-img1');
        if (img) {
            img.style.transform = `scale(${scale})`;
        }
    };

    const navigate = useNavigate();

    const handleShopClick = (category) => {
        window.scrollTo(0, 0);
        navigate(`/Shop`, { state: { category } });
    };


    return (
        <div className='grid-main'>
            <div className='grid-wrapper'>
                <div className='grid-row'>
                    <div className='grid-cell'>
                        <img src={zena1} alt='zena' className='zena-img1' />
                        <div className='img-content-wrapper1'>
                            <button onClick={() => handleShopClick(17)} className='img-content-button' onMouseEnter={(e) => handleHover(e, 1.1)}
                                onMouseLeave={(e) => handleHover(e, 1)}>ZDRAVJE</button>
                        </div>
                    </div>
                    <div className='grid-cell'>
                        <img src={zena2} alt='zena' className='zena-img1' />
                        <div className='img-content-wrapper1'>
                            <button onClick={() => handleShopClick(18)} className='img-content-button' onMouseEnter={(e) => handleHover(e, 1.1)}
                                onMouseLeave={(e) => handleHover(e, 1)}>LASJE</button>
                        </div>
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-cell'>
                        <img src={zena3} alt='zena' className='zena-img1' />
                        <div className='img-content-wrapper1'>
                            <button onClick={() => handleShopClick(20)} className='img-content-button' onMouseEnter={(e) => handleHover(e, 1.1)}
                                onMouseLeave={(e) => handleHover(e, 1)}>VITALNOST</button>
                        </div>
                    </div>
                    <div className='grid-cell'>
                        <img src={zena4} alt='zena' className='zena-img1' />
                        <div className='img-content-wrapper1'>
                            <button onClick={() => handleShopClick(19)} className='img-content-button' onMouseEnter={(e) => handleHover(e, 1.1)}
                                onMouseLeave={(e) => handleHover(e, 1)}>NEGA</button>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    )
}

export default Grid
