import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { incrementQuantity, decrementQuantity, removeFromCart } from '../../Store/actions/cartActions';
import './Cart.css'
import Navbar from '../../Components/Navbar/Navbar'
import { useNavigate } from 'react-router-dom';
import Footer from '../../Components/Footer/Footer';
import { Helmet } from 'react-helmet-async';
import { useCookies } from 'react-cookie'
import CookieConsent from '../../Cookies/CookieConsent';
import { setCoupon } from '../../Store/actions/couponActions'; // Adjust path as necessary

const Cart = () => {
    const cartItems = useSelector(state => state.cart.cartItems);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [products, setProducts] = useState([]); // State to store product data
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const [errorMessage, setErrorMessage] = useState('');
    const [cookies] = useCookies(["cookieConsent"]);
    const [couponCode, setCouponCode] = useState('');
    const [couponDiscount, setCouponDiscount] = useState('0');
    const [couponData, setCouponData] = useState(null);
    const [couponMessage, setCouponMessage] = useState('');
    const [memmory, setMemmory] = useState();


    const fetchCoupons = async () => {
        try {
            const response = await fetch(`${baseUrl}/coupons`);
            if (!response.ok) {
                throw new Error('Failed to fetch coupons');
            }
            const coupons = await response.json();
    
            // Find a specific coupon based on `couponCode`
            if (coupons) {
                // Save the entire coupon object to state
                setCouponData(coupons);
                console.log('Coupon found:', coupons);
            } else {
                console.log('Coupon code is invalid');
                setCouponData(null); // Clear previous coupon data if the new code is invalid
                setErrorMessage('Coupon code is invalid');
            }
        } catch (error) {
            console.error('Error fetching coupons:', error);
            setCouponData(null); // Ensure coupon data is cleared on error
            setErrorMessage('Error fetching coupons');
        }
    };

    const handleCouponButtonClick = () => {
        // Check if we already have coupon data
        if (couponData.length > 0) {
            // Search for a coupon in the array that matches the couponCode
            const matchingCoupon = couponData.find(coupon => coupon.code === couponCode);
    
            if (matchingCoupon) {
                // If a matching coupon is found, set its amount to couponDiscount
                setCouponDiscount(matchingCoupon.amount); // Assuming 'amount' holds the discount value
                setCouponMessage('Koda potrjena'); // Display confirmation message
                dispatch(setCoupon(matchingCoupon)); // Dispatch the action to set the coupon in the Redux store
                setMemmory(totalPrice)
                const discountRate = parseFloat(matchingCoupon.amount) / 100;
                const discountAmount = totalPrice * discountRate;
                const finalPrice = totalPrice - discountAmount;
                setTotalPrice(finalPrice);
            } else {
                // If no matching coupon is found, fetch new coupons
                setCouponDiscount('0'); // Assuming 'amount' holds the discount value
                setCouponMessage('Neveljavna koda'); // Display invalid code message
                dispatch(setCoupon(null)); // Dispatch the action to set the coupon in the Redux store

                setTotalPrice(memmory);
            }
        } else {
            // If couponData is empty, fetch new coupons
            fetchCoupons();
        }
    };

    const handleCouponChange = (event) => {
        setCouponCode(event.target.value);
    };

    useEffect(()=>{
        console.log(couponCode)

    },[couponCode])

    const handleIncrement = (itemId) => {
        dispatch(incrementQuantity(itemId));
    };

    const handleDecrement = (itemId) => {
        dispatch(decrementQuantity(itemId));
    };

    const handleRemove = (itemId) => {
        dispatch(removeFromCart(itemId));
    };

    /*const totalPrice = cartItems.reduce((total, item) => {
        return total + (parseFloat(item.price) * item.quantity);
    }, 0);*/
    const [totalPrice, setTotalPrice] = useState(0);
    useEffect(() => {
        const newTotalPrice = cartItems.reduce((total, item) => {
            return total + (parseFloat(item.price) * item.quantity);
        }, 0);
    
        setTotalPrice(newTotalPrice);
        setMemmory(newTotalPrice)

    }, [cartItems]);

    useEffect(() => {
        console.log("mem "+memmory)

    }, [memmory]);

    const handleCheckoutClick = () => {
        navigate(`/Checkout`);
    };

    // Function to fetch product data from the API
    const fetchProducts = async () => {
        try {
            const response = await fetch(`${baseUrl}/products`); // Replace baseUrl with your API endpoint
            const data = await response.json();
            setProducts(data); // Update the products state with the fetched data
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };

    useEffect(() => {
        fetchProducts(); // Fetch product data when the component mounts
        fetchCoupons();
    }, []);

    useEffect(() => {
        console.log(couponData)
    }, [couponData]);


    const validateCartItems = () => {
        let message = '';
        const updatedCartItems = cartItems.filter(cartItem => {
            const product = products.find(p => p.id === cartItem.id);
            if (product && product.stock_quantity < cartItem.quantity) {
                message = `Produkt ${product.name} je bil odstranjen iz košarice, na voljo samo še ${product.stock_quantity}`;
                handleRemove(cartItem.id);
            }
            return true;
        });

        if (message) {
            setErrorMessage(message);
        }

        // Update cart items if there are changes
        if (updatedCartItems.length !== cartItems.length) {
            // Dispatch an action to update the cart in the Redux store
            // You might need to create a new action for this or modify existing logic
        }
    };

    useEffect(() => {
        validateCartItems();
    }, [cartItems, products]);

    const isLoading = useSelector(state => state.loading);

    if (!isLoading.products && !isLoading.shippingZones) {
        return <div className='loading-div-absolute'>
            <div className="lds-ripple"><div></div><div></div></div>
            <p className='loading-paragraph'>Pripravljamo vse potrebno za prvi zagon</p>
        </div>; // Your loading screen/component here
    }

    return (
        <div>

            <Navbar />
            {!cookies.cookieConsent && <CookieConsent />}

            <div className='cart-main'>
                <div className='cart-wrapper'>
                    <h1 className='main-header'>KOŠARICA</h1>

                    <div className='kosarica-items'>
                        {cartItems.map((item, index) => (
                            <div key={index} className='cart-item'>

                                <div className='cart-row-first'>
                                    <div className='navigation-pointer'></div>
                                    <div className='items-image-wrapper'>
                                        <img src={item.images[0].src} className='redux-image' alt={item.name} />
                                    </div>
                                </div>

                                <p className='cart-item-name'>{item.name}</p>

                                <div className='reducers-div'>
                                    <p className='minus' onClick={() => handleDecrement(item.id)}>-</p>
                                    <p className='quantity-div'>{item.quantity}</p>
                                    <p className='plus' onClick={() => handleIncrement(item.id)}>+</p>
                                </div>
                                <p className='cart-item-name'>{(parseFloat(item.price) * item.quantity).toFixed(2)}€</p>


                                <div className='menu-icon4' onClick={() => handleRemove(item.id)}>
                                    <div className="menu-bar4 bar11 whitebar"></div>
                                    <div className="menu-bar4 bar22 whitebar"></div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='kosarica-items2'>
                        {cartItems.map((item, index) => (
                            <div key={index} className='cart-item2'>

                                <div className='cart-row-first'>
                                    <div className='item-and-navigator'>
                                        <div className='navigation-pointer'></div>
                                        <div className='items-image-wrapper'>
                                            <img src={item.images[0].src} className='redux-image' alt={item.name} />
                                        </div>
                                    </div>
                                    <div className='the-info'>
                                        <p className='mobile-info'>{item.name}</p>
                                        <div className='reducers-div'>
                                            <p className='minus' onClick={() => handleDecrement(item.id)}>-</p>
                                            <p className='quantity-div'>{item.quantity}</p>
                                            <p className='plus' onClick={() => handleIncrement(item.id)}>+</p>
                                        </div>
                                        <p className='mobile-info'>{(parseFloat(item.price) * item.quantity).toFixed(2)}€</p>

                                    </div>
                                </div>



                                <div className='menu-icon4' onClick={() => handleRemove(item.id)}>
                                    <div className="menu-bar4 bar11 whitebar"></div>
                                    <div className="menu-bar4 bar22 whitebar"></div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='cart-separator'></div>

                    <p className='displayerror'>{errorMessage}</p>



                    <div className='final-price-div'>
                        <div className='cart-row-first'>
                            <div className='navigation-pointer'></div>
                            <div className='input-container2'>
                                <div className='custom-wrapper1'>
                                    <div className='label-wrapper1'>
                                        <label htmlFor='customerName'>KODA KUPONA</label>
                                    </div>
                                    <input
                                        type='text'
                                        id='customerName'
                                        autoComplete=''
                                        value={couponCode}
                                        onChange={handleCouponChange}
                                        required
                                    />

                                    <button className='main-button coupon-button' onClick={handleCouponButtonClick}>UPORABI</button>
                                    <div className='couponmessage'>{couponMessage}</div>

                                </div>
                            </div>

                        </div>

                        <div className='final-price'>- {couponDiscount}%</div>
                    </div>

                    <div className='final-price-div'>
                        <div className='cart-row-first'>
                            <div className='navigation-pointer'></div>
                            <div className='placilo-div'>
                                SKUPNA CENA
                            </div>
                        </div>
                        <div className='final-price'>{totalPrice.toFixed(2)}€</div>
                    </div>




                    <div className='checkout-button-wrapper'>
                        <button className='main-button go-checkout-button' onClick={handleCheckoutClick}>NA BLAGAJNO</button>
                    </div>

                </div>

            </div>

            <Footer />
        </div>
    )
}


export default Cart
