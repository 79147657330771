import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import './CookieConsent.css'

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  return (
    <div className="cookie-consent">
      <p>
      Za izboljšanje vaše uporabniške izkušnje uporabljamo piškotke. Z uporabo naše spletne strani se strinjate z uporabo piškotkov.{" "}
        <a href={"/privacy-policy"}>Preberi več.</a>
      </p>
      <button className='main-button' onClick={giveCookieConsent}>
        Sprejmi
      </button>
    </div>
  );
};

export default CookieConsent;