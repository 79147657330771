const initialState = {
    isLoading: true,
    products: false,
    categories: false,
    posts: false,
    itemDetails: false,
    media: false,
    shippingZones: false,
};

const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return {


                ...state,
                isLoading: action.payload,
            };

        case 'SET_LOADING_PRODUCTS':
            return { ...state, products: action.payload };
        case 'SET_LOADING_CATEGORIES':
            return { ...state, categories: action.payload };
        case 'SET_LOADING_POSTS':
            return { ...state, posts: action.payload };
        case 'SET_LOADING_ITEM_DETAILS':
            return { ...state, itemDetails: action.payload };
        case 'SET_LOADING_MEDIA':
            return { ...state, media: action.payload };
        case 'SET_LOADING_SHIPPING':
            return { ...state, shippingZones: action.payload };
        default:
            return state;
    }
};

export default loadingReducer;