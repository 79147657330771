import { SET_MEDIA_DETAILS } from '../actions/mediaActions';

const initialState = {
    media: []
};

const mediaReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MEDIA_DETAILS:
            return {
                ...state,
                media: action.payload
            };
        default:
            return state;
    }
};

export default mediaReducer; 