// src/store/reducers/itemReducer.js
import { SET_SELECTED_POST } from '../actions/selectedPostAction';

const initialState = {
  selectedPost: null,
};

const itemReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_POST:
      return {
        ...state,
        selectedPost: action.payload,
      };
    default:
      return state;
  }
};

export default itemReducer;